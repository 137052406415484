import * as React from 'react';
import { FGrid, FTextInput, FSelectInput } from '@fresche/ui-lib-react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { model, TerminalCommunicationService } from '@fresche/terminal-lib';

// Note: These paths may need updated, depending on where this file lives
import BaseView from '../../base/view/base-view';
import CustomBase from './CustomBase';
import Snapshot from '../snapshot/snapshot';
import StateList from './StateList';
import OptumGrid from "../ui/OptumGrid";
import StatusList from './StatusList';

// Implement our custom Component, extending the BaseView component
export default class CustomSearchById extends BaseView {
    public prescriberData: any;
    public stateList: any;
    public selectedActionField = 'selected';
    public statusList: any;
    private readonly HELP_PAGE_WINDOW_SETTINGS = 'toolbar=no, scrollbars=yes, menubar=no, status=no, directories=no, width=600, height=400';

    // A constructor is required so we can call the super.initialize() method
    constructor(props: any, screenName: string, isDialog: boolean, isMultiPage?: boolean) {
        super(props, screenName, isDialog, isMultiPage);
        super.initialize();
        this.state = {
            items: [],
            dialogVisible: false
        }
        this.stateList = new StateList();
        this.statusList = new StatusList();
        this.tableAction = this.tableAction.bind(this);
    }

    replyToBackend(newFormData: any): void {
        console.log(newFormData);
        // ... terminal lib need a refresh of its model
        model.next({
            data: newFormData
        });
        console.log("Here");
        this.terminal.reply();
    }

    showLoader(): void {
        const loaderElement = document.getElementById('loaderModal');
        if (loaderElement) {
            loaderElement.classList.add('show');
        }
    }

    // Once the component is mounted, do an AJAX request to GitHub, which
    // we'll add to our Modal item list.
    componentDidMount(): void {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    /**
     * after page change, sent reply
     */
    pageChanged(event: string): void {
        this.showLoader();
        const replyFormData = this.formData;
        if (event === 'previous') {
            replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['PageUp'] : '07';
        } else if (event === 'next') {
            replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['PageDown'] : '08';
        } else if (event === 'reset') {
            replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['Reset'] :'05';
        }

        this.replyToBackend(replyFormData);
    }

    tableAction(command:any): void {
        if(command === "ss") {
            const replyFormData = this.formData;
            let prescriberData;
            for (let i in replyFormData['subfile']) {
                if (replyFormData['subfile'][i]['recordSelected'] == "Y") {
                    prescriberData = replyFormData['subfile'][i]['fields'];
                    replyFormData['subfile'][i]['recordSelected'] = 'N';
                    break;
                }
            }
            this.setState({ dialogVisible: true });
            this.prescriberData = prescriberData;

        } else {
            this.showLoader();
            const replyFormData = this.formData;
            for (let i = 0; i < replyFormData['subfile'].length; i++) {
                if (replyFormData['subfile'][i]['recordSelected'] === 'Y') {
                    replyFormData['subfile'][i]['recordDataChanged'] = 'Y';
                    replyFormData['subfile'][i][this.selectedActionField] = command;
                }
            }

            replyFormData['gridAction'] = command;
            replyFormData['cmdKey'] = '00';

            this.replyToBackend(replyFormData);
        }
    }

    getDropdownValues(options: any): any {
        if (options && options.length) {
            options.forEach((option: any, index: number): void => {
                options[index].label = option.label;
            });
        }
        return options;
    }

    hideDialog() {
        this.setState({ dialogVisible: false })
    }

    openHelpText() {
        const helpPage = "/help/index.htm#t=Prescriber%2FPrescriber_Snapshot.htm"
          window.open(
            helpPage,
            'Help',
            this.HELP_PAGE_WINDOW_SETTINGS
          );
          return;
      }

    // renderContent is the function in BaseView that renders the core page content, overriding
    // this method allows us to keep the header for the page from BaseView, while only customizing
    // the page specific output
    renderContent(): React.ReactElement {
        // We have to wrap the custom output in a CustomBase component, otherwise we lose access to
        // data in some components (FGrid), as well as key handlers (F3, Enter, etc).
        return (
            <CustomBase type="view" screenDef={this.screenDef} formData={this.formData} isActiveScreen={!this.displayDialog}>
                {/*<!-- Show that we can fetch external data and show it in a dialog -->*/}
                <Dialog
                    visible={this.state.dialogVisible}
                    modal={true}
                    closable={false}
                    onHide={() => this.setState({ dialogVisible: false })}
                    style={{ width: '90vw' }}
                >
                    <span className="dialog-header">
                        <h1>Prescriber Snapshot</h1>
                    </span>
                    <span className="close-button">
                        <button onClick={() => this.hideDialog()}>
                            <div id="mdiv">
                                <div className="mdiv">
                                    <div className="md"></div>
                                </div>
                            </div>
                        </button>
                    </span>
                    <span className="help-button" onClick={() => this.openHelpText()}>
                        Help
                    </span>
                
                    <br></br>
                    <br></br>
                    <br></br>
                    <Snapshot type={"prescriber"} params={this.prescriberData}></Snapshot>
                </Dialog>

                {/*<!-- Show we can still add a data grid, using the JSON/Data from the base page -->*/}
                <OptumGrid
                    model={this.formData}
                    screenDef={this.screenDef}
                    conditions = {{
                        "grid": {
                            "gridSelection": {
                                "rows": {},
                                "highintensity": true,
                                "underline": true
                            },
                            "filters": {},
                            "rcd.prfPrescriberId": {
                                "rows": {},
                                "color": "",
                                "highintensity": false,
                                "protect": true,
                                "readonly": false,
                                "hidden": false,
                                "underline": false
                            },
                            "rcd.prqQualAbbreviation": {
                                "rows": {},
                                "color": "",
                                "highintensity": false,
                                "protect": true,
                                "readonly": false,
                                "hidden": false,
                                "underline": false
                            },
                            "rcd.prfPrescriberIdState": {
                                "rows": {},
                                "color": "",
                                "highintensity": false,
                                "protect": true,
                                "readonly": false,
                                "hidden": false,
                                "underline": false
                            },
                            "rcd.wrkPhyCity": {
                                "rows": {},
                                "color": "",
                                "highintensity": false,
                                "protect": true,
                                "readonly": false,
                                "hidden": false,
                                "underline": false
                            },
                            "rcd.wrkPhyFormattedName": {
                                "rows": {},
                                "color": "",
                                "highintensity": false,
                                "protect": true,
                                "readonly": false,
                                "hidden": false,
                                "underline": false
                            },
                            "rcd.prfVendorKey": {
                                "rows": {},
                                "color": "",
                                "highintensity": false,
                                "protect": true,
                                "readonly": false,
                                "hidden": false,
                                "underline": false
                            },
                            "rcd.prfStatus": {
                                "rows": {},
                                "color": "",
                                "highintensity": false,
                                "protect": true,
                                "readonly": false,
                                "hidden": false,
                                "underline": false
                            },
                            "rcd.noteExistanceStatus": {
                                "rows": {
                                    "1": {
                                        "hidden": true,
                                        "highintensity": false,
                                        "reverseimage": false
                                    },
                                    "2": {
                                        "hidden": true,
                                        "highintensity": false,
                                        "reverseimage": false
                                    },
                                    "3": {
                                        "hidden": true,
                                        "highintensity": false,
                                        "reverseimage": false
                                    },
                                    "4": {
                                        "hidden": true,
                                        "highintensity": false,
                                        "reverseimage": false
                                    },
                                    "6": {
                                        "hidden": true,
                                        "highintensity": false,
                                        "reverseimage": false
                                    },
                                    "7": {
                                        "hidden": true,
                                        "highintensity": false,
                                        "reverseimage": false
                                    },
                                    "8": {
                                        "hidden": true,
                                        "highintensity": false,
                                        "reverseimage": false
                                    },
                                    "9": {
                                        "hidden": true,
                                        "highintensity": false,
                                        "reverseimage": false
                                    }
                                },
                                "color": "",
                                "highintensity": false,
                                "protect": true,
                                "readonly": false,
                                "hidden": false,
                                "underline": false
                            }
                        }
                    }}
                    onModelChange={(newModel: any): any => { this.formData = newModel; this.forceUpdate(); }}
                    onPageChange={(event: any): void => { this.pageChanged(event); }}
                    onActionButton={this.tableAction}
                />
            </CustomBase>
        );
    }

}
