import * as React from 'react';
import Moment from 'moment';

interface TextDisplayProps {
  input: any;
  formData: any;
  dropdownValue?: any;
}

function TextDisplay(props: TextDisplayProps) {
  const label = props.input?.label;
  const header1 = props.input?.header1;
  let value = props.formData['fields'][props.input.field];


  if (typeof value == "string") {
    if (value.indexOf("-") != -1) {
      if (value.length == 10 && value.indexOf("-") == 4) {
        const date = Moment(value);
        value = date.format("MM/DD/YYYY");
        console.log(value);
      }
    }
  }

  if (props.input?.type == "dropdown" && props.dropdownValue != null) {
    props.dropdownValue.forEach(e => {
      if (e?.value === props.formData['fields'][props.input.field]) {
        value = e?.label;
      }
    });
  }

  return (
    <div className="display-val">
      <div>{label !== "" ? (
        <div><strong>{label}</strong>:&nbsp;{value}</div>
      ) : header1 !== "" ? (
        <div><strong>{header1}</strong>:&nbsp;{value}</div>
      ) :
          <div>{value}</div>}
      </div>
    </div>
  )
}

export default TextDisplay;