import {TerminalCommunicationService} from "@fresche/terminal-lib";
import {getProgramParameters} from "./OptumPrograms";

declare global {
    interface Window { config: any; }
}

/**
 * This OptumTerminalCommunicationService "extends" Fresche TerminalCommunicationService to provide a way to start
 * a specific process directly (via a process name/class and optional parameters)
 */
export default class OptumTerminalCommunicationService {
    apiBaseUrl: string;
    terminal: TerminalCommunicationService;

    constructor() {
        this.apiBaseUrl = window.config['backend'] ? window.config['backend']['apiBaseUrl'] : '';
        this.terminal = new TerminalCommunicationService();
    }

    /**
     * Starts a specific program from its code with optional parameters
     *
     * @param programName the program name
     * @param inputParameterArray the optional input parameters
     */
    startSpecificProcess(programName: string, inputParameterArray?: any): void {
        this.stopCurrentProcess();

        const program = getProgramParameters(programName);
        if (program){
            // build named parameter with params from url (by position)
            const inputParameter = {}
            for (let i = 0; i < program.inputParameterNames?.length; i++) {
                inputParameter[program.inputParameterNames[i]] = decodeURIComponent(inputParameterArray[i]);
            }

            this.terminal.init({}, {
                programName: program.programName,
                inputParameter,
                inputParameterClass: program.inputParameterClass
            }, window.config);
        }
    };

    /**
     * Clears the current process if there is already one (id present in sessionStorage)
     */
    protected stopCurrentProcess(): void {
        var terminalProcess = JSON.parse(sessionStorage.getItem('terminalProcess') || '{}');

        if (terminalProcess !== {} && terminalProcess.pid) {
            sessionStorage.setItem('terminalProcess', '{}');
            fetch(this.apiBaseUrl + '/v2/process/' + terminalProcess.pid, {
                method: 'delete',
                mode: 'cors',
            })
        }
    };

}