import * as React from 'react';
import AppHeader from '../app-header/AppHeader';
import BaseView from '../../base/view/base-view';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import './NotFound.css';

export default class NotFound extends BaseView<any, any> {
    constructor(props: any) {
        super(props, 'NotFound', false, false);
    }

    handleHomeClick(): void {
        this.terminal.stopProcess();
        this.terminal.init({}, {}, window.config);
    }

    render(): React.ReactElement {
        const header = <img alt="Card" src='./images/notfound.png' />;
        const footer = (<span>
            <Button
                label="Home"
                icon="pi pi-home"
                onClick={(e: any): void => {
                    e.preventDefault();
                    this.handleHomeClick();
                }}
            />
        </span>);
        return (
            <React.Fragment>
                <AppHeader
                    screenDef={null}
                    formData={null}
                />
                <Card footer={footer} header={header} title="Error: 404" subTitle="Page Not Found" className="card ui-card-shadow">
                    The page you are trying to access does not exist.
                </Card>
            </React.Fragment>
        );
    }
}
