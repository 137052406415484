import * as React from 'react';
import { TerminalCommunicationService } from '@fresche/terminal-lib';
import { Button } from 'primereact/button';
import { MegaMenu } from 'primereact/megamenu';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dialog } from 'primereact/dialog';
import './AppHeader.css';

/**
 *
 */
declare global {
  interface Window { config: any; }
}

/**
 *
 */
export interface AppHeaderProps {
  screenDef: any;
  formData: any;
  left?: (i: any) => React.ReactElement;
  right?: (i: any) => React.ReactElement;
}

/**
 *
 */
export interface AppHeaderState {
  infoVisible: boolean;
}

class AppHeader extends React.Component<AppHeaderProps, AppHeaderState> {
  private logoSrc = './images/logo.png';

  menu: MegaMenu; // = React.createRef();
  rightmenu: OverlayPanel; // = React.createRef();

  private displayScreenDef: boolean;
  private menuItems: any = [
    {
      label: 'Screen info',
      icon: 'pi pi-info',
      items: [[

      ]]
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: (): void => this.logout()
    },
  ];

  private terminal = new TerminalCommunicationService();

  /**
   *
   *
   */
  constructor(props: AppHeaderProps) {
    super(props);
    this.state = { infoVisible: false };
  }

  /**
   *
   */
  componentDidMount(): void {
    this.addScreenInfo();
    // this.addLanguages();
    this.addDebug();
  }

  /**
   * Adds a list of debug options to the menu.
   */
  addDebug(): void {
    if (window.config && window.config['debug']) {
      this.menuItems.unshift({
        label: 'Debug',
        icon: 'pi pi-exclamation-circle',
        items: [[
          {
            items: [
              {
                label: 'Screen Definition',
                command: (): void => {
                  this.displayScreenDef = true;
                }
              }
            ]
          }
        ]]
      });
    }
  }

  /**
   * Adds information about the current screen to the menu.
   * https://primefaces.org/primeng/showcase/#/megamenu
   */
  addScreenInfo(): void {
    if (this.props.screenDef && this.props.formData) {
      this.menuItems[0].items[0].push(
        {
          label: this.props.screenDef.programName,
          items: [
            { label: this.props.formData.programMode },
            { label: window.config['app'] ? window.config['app']['version'] : '' }
          ]
        }
      );
    }
  }

  /**
   *
   */
  toggleInfo(): void {
    this.setState({ infoVisible: !this.state.infoVisible });
  }

  /**
   *
   */
  getDate(): string {
    const today = new Date();
    const dd = (today.getDate() < 10) ? '0' + today.getDate() : today.getDate();
    const month = today.getMonth() + 1;
    const mm = (month < 10) ? '0' + month : month;
    const yyyy = today.getFullYear();
    const date = [mm, dd, yyyy];

    return date.join('/');
  }

  /**
   *
   */
  logout(): void {
    this.terminal.stopProcess();
  }

  onHideDebugScreen(): void {

  }

  /**
   *
   */
  render(): React.ReactElement {
    return (
      <React.Fragment>
        <Dialog header="Screen Definition" appendTo={document.body} maximizable={true} modal={true} visible={this.displayScreenDef} onHide={(): void => this.onHideDebugScreen()}>
          {this.props.screenDef && (
            <div>sdfdsfs</div>
          )}
        </Dialog>
      </React.Fragment >
    );
  }
}

export default AppHeader;
