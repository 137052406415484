import React from 'react';
import ReactDOM from 'react-dom';
import process from 'process';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { generateFetchWithSession, generateFetchWithTenantId } from "./security/AuthentifiedFetch";

import * as AuthProxy from './security/AuthProxy';

const fileConfig = (process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL + './config.json' : './config.json');

fetch(fileConfig)
    .then((res: any): any => {
        return res.json();
    })
    .then((env: any): any => {
        // @ts-ignore
        window['version'] = env.app.version;
        const query = new URLSearchParams(window.location.search);
        const code = query.get('code');
        const tenantId = query.get('tenantId');
        console.log(code);
        const sessionId = query.get('sessionId');
        console.log(sessionId);
        const authType = query.get('authType');
        if (env['authentication'] === 'no-oauth') {
            alert("WARNING : application is running without authentication!");
            window.fetch = generateFetchWithTenantId(env['tenant-id']);
            return ReactDOM.render(<App env={env} />, document.getElementById('root'));
        }
        if( sessionId != null && sessionId != undefined) {
            AuthProxy.setSessionId(encodeURIComponent(sessionId));
            AuthProxy.setAuthType(encodeURIComponent(authType));
            AuthProxy.setTenantId(encodeURIComponent(tenantId))            
            sessionStorage.setItem('userWantToQuit', 'true');
        }
        if ((typeof (AuthProxy.getSessionId()) == 'undefined' || AuthProxy.getSessionId() == null)
            && (code === null || code === 'undefined')) {
            sessionStorage.setItem('authType', encodeURIComponent('msid'));
            AuthProxy.loginRedirect();
            return;
        }
        console.log(env['authentication']);
        if(env['authentication'] === 'auth-proxy') {
            if (!AuthProxy.getSessionId() && code) {
                AuthProxy.session(code)
                .then((res: any) => res.json())
                .then((data) => {
                    AuthProxy.setSessionId(encodeURIComponent(data['sessionId']));
                    AuthProxy.setAuthType(encodeURIComponent('msid'));
                    window.fetch = generateFetchWithSession(decodeURIComponent(data['sessionId']),'msid');
                    return ReactDOM.render(<App env={env} />, document.getElementById('root'));
                })
            } else if(AuthProxy.getSessionId()) {
                window.fetch = generateFetchWithSession(decodeURIComponent(AuthProxy.getSessionId()), decodeURIComponent(AuthProxy.getAuthType()));
                return ReactDOM.render(<App env={env} />, document.getElementById('root'));
            }
        }

    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
