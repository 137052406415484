const CryptoJs = require('crypto-js');

export function session(code:string): Promise<Response> {
    const requestBody = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'code': code })
    };
    return fetch(`./session?redirect_uri=${window.location.origin}${window.location.pathname}`, requestBody);
}

export function loginRedirect() {
    //sanitization
    const hrefEncode= encodeURIComponent(JSON.stringify(window.location.href));
    const sanitiiizedhref = JSON.parse(decodeURIComponent(hrefEncode));
    window.location.href = `./login?redirect_uri=${sanitiiizedhref}`;
}

export function logoutUser() {
    if("ohid" === decodeURIComponent(getAuthType())) {
       return fetch(`./secure/logout?auth_type=${decodeURIComponent(getAuthType())}&ohid=true`);
    } else{
        return fetch(`./secure/logout?auth_type=${decodeURIComponent(getAuthType())}`);
    }
}

export  function encrypt(text: string ){
    const pass =process!==undefined?process.env.REACT_APP_API_AES_PASS:"default@optum";    
    return CryptoJs.AES.encrypt(text,pass).toString();
}

export function decrypt(cipherText: string ){
    const pass =process!==undefined?process.env.REACT_APP_API_AES_PASS:"default@optum";
    const bytes = CryptoJs.AES.decrypt(cipherText, pass);
    const text = bytes.toString(CryptoJs.enc.Utf8);
    return text;
}

export function getSessionId(){
    return decrypt(String(sessionStorage.getItem('sessionId')));
 }
 
 export function setSessionId(sessionId: string){
     sessionStorage.setItem("sessionId", encrypt(sessionId));
 }
 export function getAuthType(){
    return decrypt(String(sessionStorage.getItem('authType')));
 }
 
 export function setAuthType(authType: string){
     sessionStorage.setItem("authType", encrypt(authType));
 }

 export function getTenantId(){
    return decrypt(String(sessionStorage.getItem('tenantId')));
 }
 
 export function setTenantId(tenantId: string){
     sessionStorage.setItem("tenantId", encrypt(tenantId));
 }