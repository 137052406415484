import * as React from 'react';

export default class StateList {
    getStates() {
        let options = [
            {
                value: "*",
                label: "* - All States"
            },
            {
                value: "AB",
                label: "AB - Alberta"
            },
            {
                value: "AK",
                label: "AK - Alaska"
            },
            {
                value: "AL",
                label: "AL - Alabama"
            },
            {
                value: "AR",
                label: "AR - Arkansas"
            },
            {
                value: "AS",
                label: "AS - American Samoa"
            },
            {
                value: "AZ",
                label: "AZ - Arizona"
            },
            {
                value: "BC",
                label: "BC - British Columbia"
            },
            {
                value: "CA",
                label: "CA - California"
            },
            {
                value: "CO",
                label: "CO - Colorado"
            },
            {
                value: "CT",
                label: "CT - Connecticut"
            },
            {
                value: "DC",
                label: "DC - District of Columbia"
            },
            {
                value: "DE",
                label: "DE - Delaware"
            },
            {
                value: "FL",
                label: "FL - Florida"
            },
            {
                value: "FM",
                label: "FM - Fed States of Micronesia"
            },
            {
                value: "GA",
                label: "GA - Georgia"
            },
            {
                value: "GU",
                label: "GU - Guam"
            },
            {
                value: "HI",
                label: "HI - Hawaii"
            },
            {
                value: "IA",
                label: "IA - Iowa"
            },
            {
                value: "ID",
                label: "ID - Idaho"
            },
            {
                value: "IL",
                label: "IL - Illinois"
            },
            {
                value: "IN",
                label: "IN - Indiana"
            },
            {
                value: "KS",
                label: "KS - Kansas"
            },
            {
                value: "KY",
                label: "KY - Kentucky"
            },
            {
                value: "LA",
                label: "LA - Louisiana"
            },
            {
                value: "MA",
                label: "MA - Massachusetts"
            },
            {
                value: "MB",
                label: "MB - Manitoba"
            },
            {
                value: "MD",
                label: "MD - Maryland"
            },
            {
                value: "ME",
                label: "ME - Maine"
            },
            {
                value: "MH",
                label: "MH - Marshall Islands"
            },
            {
                value: "MI",
                label: "MI - Michigan"
            },
            {
                value: "MN",
                label: "MN - Minnesota"
            },
            {
                value: "MO",
                label: "MO - Missouri"
            },
            {
                value: "MP",
                label: "MP - Northern Mariana Islands"
            },
            {
                value: "MS",
                label: "MS - Mississippi"
            },
            {
                value: "MT",
                label: "MT - Montana"
            },
            {
                value: "NB",
                label: "NB - New Brunswick"
            },
            {
                value: "NC",
                label: "NC - North Carolina"
            },
            {
                value: "ND",
                label: "ND - North Dakota"
            },
            {
                value: "NE",
                label: "NE - Nebraska"
            },
            {
                value: "NF",
                label: "NF - Newfoundland"
            },
            {
                value: "NH",
                label: "NH - New Hampshire"
            },
            {
                value: "NJ",
                label: "NJ - New Jersey"
            },
            {
                value: "NM",
                label: "NM - New Mexico"
            },
            {
                value: "NS",
                label: "NS - Nova Scotia"
            },
            {
                value: "NT",
                label: "NT - Northwest Territories"
            },
            {
                value: "NU",
                label: "NU - Nunavut"
            },
            {
                value: "NV",
                label: "NV - Nevada"
            },
            {
                value: "NY",
                label: "NY - New York"
            },
            {
                value: "OH",
                label: "OH - Ohio"
            },
            {
                value: "OK",
                label: "OK - Oklahoma"
            },
            {
                value: "ON",
                label: "ON - Ontario"
            },
            {
                value: "OR",
                label: "OR - Oregon"
            },
            {
                value: "PA",
                label: "PA - Pennsylvania"
            },
            {
                value: "PE",
                label: "PE - Prince Edward Island"
            },
            {
                value: "PR",
                label: "PR - Puerto Rico"
            },
            {
                value: "PW",
                label: "PW - Palau"
            },
            {
                value: "QC",
                label: "QC - Quebec"
            },
            {
                value: "RI",
                label: "RI - Rhode Island"
            },
            {
                value: "SC",
                label: "SC - South Carolina"
            },
            {
                value: "SD",
                label: "SD - South Dakota"
            },
            {
                value: "SK",
                label: "SK - Saskatchewan"
            },
            {
                value: "TN",
                label: "TN - Tennessee"
            },
            {
                value: "TX",
                label: "TX - Texas"
            },
            {
                value: "UT",
                label: "UT - Utah"
            },
            {
                value: "VA",
                label: "VA - Virgina"
            },
            {
                value: "VI",
                label: "VI - Virgin Islands"
            },
            {
                value: "VT",
                label: "VT - Vermont"
            },
            {
                value: "WA",
                label: "WA - Washington"
            },
            {
                value: "WI",
                label: "WI - Wisconsin"
            },
            {
                value: "WV",
                label: "WV - West Virginia"
            },
            {
                value: "WY",
                label: "WY - Wyoming"
            },
            {
                value: "YT",
                label: "YT - Yukon"
            }
        ];
        return options;
    }
}