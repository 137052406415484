import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { RouteComponentProps } from "react-router";
import OptumTerminalCommunicationService from "../../util/OptumTerminalCommunicationService";

interface StartProgramProps extends RouteComponentProps<{}> {
}

class StartProgram extends React.Component<StartProgramProps, {}> {
    terminal: OptumTerminalCommunicationService;

    constructor(props: StartProgramProps) {
        super(props);
        this.terminal = new OptumTerminalCommunicationService();
        const programPath = this.props.location.pathname;
        const programParams = programPath.substr(programPath.indexOf('/program/')+9).split("/");
        const programName = programParams.shift();

        this.terminal.startSpecificProcess(
            programName,
            programParams);
    }

    render(): React.ReactElement {
        return <div id="loaderModal" className="modal">
            <ProgressSpinner className="loader" />
        </div>
    }
}

export default StartProgram;

