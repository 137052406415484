import Menu from './layout/menu/Menu';
import OnError from './layout/on-error/OnError';
import NoAuth from './layout/no-auth/NoAuth';
import Logout from './layout/logout/Logout';
import BaseViewComponent from './base/view/base-view';
import StartProgram from "./components/programs/StartProgram";
import CustomSearchById from './layout/custom/CustomSearchById';

const routes = [
  {
    path: '/masterMenu',
    component: Menu
  },
  {
    path: '/on-error',
    component: OnError
  },
  {
    path: '/program/**',
    component: StartProgram
  },
  {
    path: '/logout',
    component: Logout
  },
  {
    path: '/no-auth',
    component: NoAuth
  },
  {
    path: '/prfPrescriberId/rcprf002SearchByIdactv',
    component: CustomSearchById
  },
  {
    path: '**',
    component: BaseViewComponent
  }
];

export default routes;
