const { prestoURL, eligibilityURL, transformedCodeURL } = window['runConfig'] || {};

type programInformation = {
    programName: string,
    inputParameterClass?: string,
    inputParameterNames?: string[]
}

type programInformations = {
    [name: string]: programInformation
}

const programs: programInformations = {
    RCPRF002: {
        programName: 'com.optum.file.prfprescriberid.program.rcprf002searchbyidactv.IRcprf002SearchByIdactvService'
    },
    RCDSF002: {
        programName: 'com.optum.file.doctorspecialty.program.rcdsf002dsplydoctorsp.IRcdsf002DsplyDoctorSpService'
    },
    RCPAQ001: {
        programName: 'com.optum.file.paqprescriberaddrqual.program.rcpaq001mntaddrsqual.IRcpaq001MntAddrsQualService'
    },
    RCSCF002: {
        programName: 'com.optum.file.scfsanctioncodefile.program.rcscf002displaysanction.IRcscf002DisplaySanctionService'
    },
    RCHTC005: {
        programName: 'com.optum.file.htchcideataxonomycode.program.rchtc005taxonomyscrndrv.IRchtc005TaxonomyScrndrvService'
    },
    RCSCF003I: {
        programName: 'com.optum.file.scfsanctioncodefile.program.rcscf003idispsanctioncd.IRcscf003idispSanctionCdService',
        inputParameterClass: 'com.optum.file.scfsanctioncodefile.program.rcscf003idispsanctioncd.Rcscf003idispSanctionCdParams',
        inputParameterNames: ["scfSanctionCode"]
    },
    // exemple : /program/RCPHY010I/@000010754/1881701787/*/01
    RCPHY010I: {
        programName: 'com.optum.file.phyprescriber.program.rcphy010idspprscbrdtl.IRcphy010iDspPrscbrDtlService',
        inputParameterClass: 'com.optum.file.phyprescriber.program.rcphy010idspprscbrdtl.Rcphy010iDspPrscbrDtlParams',
        inputParameterNames: ["phyPrescriberKey", "prfPrescriberId", "prfPrescriberIdState", "prqPrscrbrIdQualifier"]
    },
    RCADR001: {
        programName: 'com.optum.file.adralowdrugschdfordea.program.rcadr001adractvdrugdsp.IRcadr001AdrActvDrugdspService',
        inputParameterClass: 'com.optum.file.adralowdrugschdfordea.program.rcadr001adractvdrugdsp.Rcadr001AdrActvDrugdspParams',
        inputParameterNames: ["phyPrescriberKey"]
    },
    RCPRF012: {
        programName: 'com.optum.file.prfprescriberid.program.rcprf012otherprfidsact.IRcprf012OtherPrfIdsactService',
        inputParameterClass: 'com.optum.file.prfprescriberid.program.rcprf012otherprfidsact.Rcprf012OtherPrfIdsactParams',
        inputParameterNames: ["phyFirstName", "phyPrescriberKey", "wrkPrfPrescriberId1", "wrkPrfPrscrbrIdSte1", "wrkPrfQualAbbrev1", "phyVendorKey",
         "rqstFrmSnpshtFrPrscbrInfo"]
    },
    RCPSP001: {
        programName: 'com.optum.file.pspprescriberspecialty.program.rcpsp001prescrbspecdsp.IRcpsp001PrescrbSpecDspService',
        inputParameterClass: 'com.optum.file.pspprescriberspecialty.program.rcpsp001prescrbspecdsp.Rcpsp001PrescrbSpecDspParams',
        inputParameterNames: ["phyLastName", "phyPrescriberKey", "prfPrescriberId", "prfPrescriberIdState", "prqQualAbbreviation",
         "rqstFrmSnpshtFrSpclInfo" ]
    },
    RCPHZ001: {
        programName: 'com.optum.file.phzprescribertaxonomy.program.rcphz001taxo4prscrbract.IRcphz001Taxo4prscrbrActService',
        inputParameterClass: 'com.optum.file.phzprescribertaxonomy.program.rcphz001taxo4prscrbract.Rcphz001Taxo4prscrbrActParams',
        inputParameterNames: ["phyLastName", "phyPrescriberKey", "prfPrescriberId", "prfPrescriberIdState", "prqPrscrbrIdQualifier",
         "rqstFrmSnpshtFrTaxNmy"]
    },
    RCPH2001: {
        programName: 'com.optum.file.ph2npidetails.program.rcph2001editnpidetails.IRcph2001EditNpiDetailsService',
        inputParameterClass: 'com.optum.file.ph2npidetails.program.rcph2001editnpidetails.Rcph2001EditNpiDetailsParams',
        inputParameterNames: ["prfPrescriberId", "prfPrescriberIdState", "prqPrscrbrIdQualifier" , "rqstFrmSnpshtFrNpiDtls"]
    },
    RCPSR015: {
        programName: 'com.optum.file.psrprescribersanction.program.rcpsr015actvsancmaster.IRcpsr015ActvSancMasterService',
        inputParameterClass: 'com.optum.file.psrprescribersanction.program.rcpsr015actvsancmaster.Rcpsr015ActvSancMasterParams',
        inputParameterNames: ["phyLastName","phyPrescriberKey", "prfPrescriberId", "prfPrescriberIdState", "prqPrscrbrIdQualifier",
         "rqstFrmSnpshtFrSctnMstrScreen"]
    }
};

export function getProgramParameters(programName: string): programInformation | undefined {
    return programs[programName];
}


export function getProgramUrl(programName: string, parameters?: string[]): string | undefined {
    // get program informations
    // @ts-ignore
    let url = undefined;
    // return program url
        const encodedParameters: string[] = parameters && parameters.length > 0 ? parameters.map(elt=> encodeURIComponent(elt as string)) : [];
        const urlParameters = encodedParameters.length > 0 ? "/" + encodedParameters.join("/") : "";
        url = `/program/${programName}${urlParameters}`
    
    console.log("Navigating to " + url);
    return url;
}

