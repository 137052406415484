import * as React from 'react';
import AppHeader from '../app-header/AppHeader';
import BaseView from '../../base/view/base-view';
import { Button } from 'primereact/button';
import './NoAuth.css';

export default class NoAuth extends BaseView<any, any> {
    constructor(props: any) {
        super(props, 'NotAuthorized', false, false);
    }

    async componentDidMount() {
        if (sessionStorage['terminalProcess']) {
            await this.stopCurrentProcess();
        }
    }

    handleHomeClick(): void {
        if (sessionStorage['terminalProcess']) {
            this.stopCurrentProcess().then(() => {
                this.redirectLogic();
            });
        } else {
            this.redirectLogic();
        }

    }
    redirectLogic() {
        if (window.self !== window.top && window.self.name === "main-iframe") {
            const url = window.location !== window.parent.location ? document.referrer : document.location.href;
            window.parent.postMessage("close-iframe", '*');
        } else {
            this.terminal.init({}, {}, window.config);
        }
    }
    async stopCurrentProcess(): Promise<void> {
        var terminalProcess = JSON.parse(sessionStorage.getItem('terminalProcess'));

        if (terminalProcess !== {} && terminalProcess.pid) {
            sessionStorage.setItem('terminalProcess', '{}');
            await fetch((window.config['backend'] ? window.config['backend']['apiBaseUrl'] : '') + '/v2/process/' + terminalProcess.pid, {
                method: 'delete',
                mode: 'cors',
            })
        }
    };

    render(): React.ReactElement {
        return (
            <React.Fragment>
                <AppHeader
                    screenDef={null}
                    formData={null}
                />
                <div className="centerText">
                    <h1 className="h1MsgFont"> You do not have permission to view this page.</h1>
                    <h2 className="h2MsgFont">Please reach out to administrator for the access.</h2>
                    <hr></hr>
                    <span>
                        <Button
                            label="Home"
                            icon="pi pi-home"
                            onClick={(e: any): void => {
                                e.preventDefault();
                                this.handleHomeClick();
                            }}
                        />
                    </span>
                </div>
            </React.Fragment>
        );
    }
}
