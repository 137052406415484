import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { TerminalCommunicationService, processErrors, endOfProcess } from '@fresche/terminal-lib';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Messages } from 'primereact/messages';
import { model } from '@fresche/terminal-lib';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import '@fresche/ui-lib-react/dist/index.css';
import '@fresche/common-lib/dist/assets/variables.css';
import './App.css';
import './assets/css/fresche-layout.css';
import './assets/css/styles.css';
import './assets/css/custom.css';

let terminal: TerminalCommunicationService;
declare global {
  interface Window { config: any; }
}

class App extends React.Component<any, any> {
  terminal: any;
  messageService: Messages;

  /**
   *
   */
  constructor(props: any) {
    super(props);

    // Easy hack by replace previous name window.config by the props.env received from the JSON Config file.
    window.config = props.env;
    terminal = new TerminalCommunicationService();
    
    // Remove the terminal Process session id if code is 410 [GONE] 
    if(sessionStorage && sessionStorage.getItem("terminalProcess") && 
       JSON.parse(sessionStorage.getItem("terminalProcess")).code != "410"){
        sessionStorage.removeItem("terminalProcess");
    }
    
    if (window.location.href.indexOf("/program/") < 0) {
      // Do not initialize with empty if accessing a direct url.
      terminal.init({}, {}, window.config);
    }

    this.handleConfirmationKeys = this.handleConfirmationKeys.bind(this);
  }

  /**
   *
   */
  componentDidMount(): void {
    this.initializeApp();
  }

  /**
   *
   */
  initializeApp(): void {
    // Handles session errors
    processErrors.subscribe((m: any): void => {
      if (m && m['code'] && m['message']) {
        this.messageService.clear();
        this.messageService.show({ severity: 'error', summary: `Process Error: ${m['code']}`, detail: m['message'] });
        processErrors.next(null);
      }
    });

    // Handle end of process
    endOfProcess.subscribe((data: any): void => {
      if (data && data.redirectUrl) {
        window.location.href = data.redirectUrl;
        endOfProcess.next(null);
      }
    });

    sessionStorage.setItem('showLeavingQuestion', 'true');  
    window.addEventListener('beforeunload', this.beforeUnload);
    window.addEventListener('unload', this.unload);      
    document.addEventListener('keydown', this.handleConfirmationKeys);
  }

  /**
   *
   */
  componentWillUnmount(): void {
    if (processErrors) {
      processErrors.unsubscribe();
    }

    if (endOfProcess) {
      endOfProcess.unsubscribe();
    }
    document.removeEventListener('keydown', this.handleConfirmationKeys);
    window.addEventListener('beforeunload', this.beforeUnload);
    window.addEventListener('unload', this.unload);
  }


  beforeUnload(e: any) {
    const askToLeave = sessionStorage.getItem('showLeavingQuestion');
   // In all case we put this value to true, user code should set it to false to by pass the question
    sessionStorage.setItem('showLeavingQuestion', 'false');
   if (askToLeave === 'true') {
      sessionStorage.setItem('userWantToQuit', 'true');
     // User is leaving or closing the tab (browser)
      e.preventDefault();
      e.returnValue = '';
    }
  }

 unload(_e: any) {
    const userWantToQuit = sessionStorage.getItem('userWantToQuit');
   // reset for other slice when the application just unload normally (not when the user close the browser tab)
    sessionStorage.setItem('userWantToQuit', 'false');    
   if (userWantToQuit === 'true') {
      model.next({
        data: { logout: true }
      }
      );
     terminal.reply();
     // Patch
     const time = Date.now();
     while ((Date.now() - time) < 1000) {

     }
     sessionStorage.clear();
     return true;
    }
  }
  
  /**
   * accept a confirmation on Y
   * rejects a confirmation on N
   */
  handleConfirmationKeys(event: KeyboardEvent): void {
    // the tabulation needs to loop trhough the screen onlly
    if (event.code === 'Tab') {
      // list all focussable elements
      const selectors = [
        'input:not([disabled]):not([readonly]):not([tabindex="-1"])',
        'select:not([disabled]):not([readonly]):not([tabindex="-1"])',
        'textarea:not([disabled]):not([readonly]):not([tabindex="-1"])'
      ];
      const focusables = document.querySelectorAll(selectors.join(','));

      const firstFocusable = focusables[0];
      const lastFocusable = focusables[focusables.length - 1];

      // if shift key go to previous else goto next
      if (event.shiftKey) {
        // if the tab occur on first element, return to the last element of screen
        if (event.target === firstFocusable) {
          event.preventDefault();

          const toFocus: any = lastFocusable;
          toFocus.focus();
        }
      } else {
        // if the tab occur on last element, return to the first element of screen
        if (event.target === lastFocusable) {
          event.preventDefault();

          const toFocus: any = firstFocusable;
          toFocus.focus();
        }
      }
    }
  }

  /**
   *
   */
  render(): React.ReactElement {
    return (
      <React.Fragment>

        <div id="loaderModal" className="modal">
          <ProgressSpinner className="loader" />
        </div>

        <Messages ref={(el: any): void => this.messageService = el} />

        <BrowserRouter basename={window.config && window.config['app'] ? window.config['app']['basedir'] : '/'}>
          <Router />
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
