import * as React from 'react';
import { Card } from 'primereact/card';
import './Logout.css';
import { TerminalCommunicationService } from '@fresche/terminal-lib';

export default class Logout extends React.Component {
    protected terminal = new TerminalCommunicationService();
    constructor(props: any) {
        super(props);
    }

    handleHomeClick(): void {
        this.terminal.stopProcess();
    }

    render(): React.ReactElement {
        return (
            <React.Fragment>
                <div className="logoutPage">
                    <Card subTitle="Sign Off Successful" className="card ui-card-shadow">
                        You have been successfully logged out of the application.
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}
