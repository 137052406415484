import * as React from "react";
import { Button } from "primereact/button";
import { FGrid } from "@fresche/ui-lib-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { RefObject } from "react";
import { Menu } from "primereact/menu";

import { InputText } from "primereact/inputtext";

import {FGridProps} from "@fresche/ui-lib-react/dist/lib/grid/f-grid/f-grid";
import CameraIcon from '@material-ui/icons/CameraAlt';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import NoteIcon from '@material-ui/icons/Note';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';


export default class OptumGrid extends FGrid {
    public gearMenu: RefObject<Menu> = React.createRef<Menu>();
    public dt: RefObject<DataTable> = React.createRef<DataTable>();

    public constructor(props:FGridProps) {
        super(props);
        this.applySort = this.applySort.bind(this);
    }

    getGridDataTable(): React.ReactElement {
        // const parentTable = super.getGridDataTable();
        // NOT POSSIBLE TO MODIFY parentTable
        // return parentTable;

        return <DataTable
            autoLayout={true}
            resizableColumns={false}
            columnResizeMode={'fit'}
            // @ts-ignore
            totalRecords={this.totalRecords}
            paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport "
            currentPageReportTemplate="Rows per page"
            rowsPerPageOptions={[10, 25, 50]}
            pageLinkSize={1}
            rowHover={true}
            dataKey="index"
            paginator={true}
            // @ts-ignore
            rows={this.numRows}
            lazy={true}
            // @ts-ignore
            first={this.firstPage}
            value={this.props.model['subfile']}
            header={
                <Menu
                    model={
                        this.getMenuItems()
                    }
                    popup
                    id={"popup_menu"}
                    ref={this.gearMenu}
                    // @ts-ignore
                    baseZIndex={3000}
                    appendTo={document.body}
                    // @ts-ignore
                    baseZIndex={9999}
                    style={{zIndex:9999}}
                />
            }
            // header={this.getGridDataTableHeader()}
            // @ts-ignore
            selection={this.selectedRows}
            onPage={this.getPage}
            onSelectionChange={(e: any): void => this.onSelectionChange(e.value)}
            rowClassName={this.rowClass}
            ref={this.dt}
            onSort={this.applySort}
            sortField={this.getSortField()} sortOrder={this.getSortOrder()}
        >
            {(this.selectionMode !== 'none' && this.selectionMode !== 'noradio') && (
                <Column className="no-default-select" selectionMode={this.selectionMode} style={{ width: '45px', maxWidth: '45px' }} />
            )}
            {/* Add gear column */}
            {this.selectionMode !== 'none' && (
                <Column style={{ width: '300px' }} key={0} body={(rowData: any, props: any) =>
                    <Button
                        label={"settings"}
                        type="button"
                        className="p-button-secondary p-button-gear material-icons"
                        onClick={(event) => {
                            this.gearMenu.current.setState({
                                rowData: rowData
                            });
                            this.gearMenu.current.toggle(event)
                        }}
                        aria-controls="popup_menu"
                        aria-haspopup
                    />
                } />
            )}
            {
                // @ts-ignore
                this.gridDefinition.columns.map((col: any, index: any): any => {
                    if (!this.hiddenColumn(col[0].field)) {
                        return <Column key={index}
                                       style={this.getColumnWidth(col[0])}
                                       field={col[0].field}
                                       header={this.getColumnHeader(col[0])}
                                       columnKey={(index).toString()}
                                       body={this.cellBodyTemplate}
                                       sortable={this.isSortable(col[0])}/>;
                    } else {
                        return null;
                    }
                })
            }
        </DataTable >
    }

    applySort(event:any): void {
        const fieldName = event.sortField.replaceAll("rcd.", "");
        const fieldOrder = event.sortOrder === 1 ? "ASC" : "DESC";

        // test if previous sortby on the same field was set.
        // this allows us to do "ASC", then "DESC", then reset
        const fieldOrderOpposite = event.sortOrder === 1 ? "DESC" : "ASC";
        const eventualPreviousSortBy = `{"${fieldName}":"${fieldOrderOpposite}"}`
        if (fieldOrder === "ASC"
             && this.props.model['sortData'] === eventualPreviousSortBy){
            this.props.model['sortData'] = "";
        } else {
            this.props.model['sortData'] = `{"${fieldName}":"${fieldOrder}"}`;
        }
        this.props.onPageChange('reset');
    }

    getPage(event: any): void {
        // @ts-ignore
        if (this.numRows !== event['rows']) {
            // @ts-ignore
            this.numRows = event['rows'];
            this.props.model['subfilePageSize'] = event['rows'];
            this.props.onPageChange('reset')
        } else {
            super.getPage(event);
        }
    }

    getIcon(command) {
        if(command == "ss") {
            return <CameraIcon style={{ color: '#316BBE' }}/>
        } else if(command == "2") {
            return <CreateIcon style={{ color: '#316BBE' }}/>
        } else if(command == "5") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "7") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "9") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "4") {
            return <DeleteIcon style={{ color: '#316BBE' }}/>
        } else if(command == "0") {
            return <NoteIcon style={{ color: '#316BBE' }}/>
        } else if(command == "1") {
            return <AddIcon style={{ color: '#316BBE' }}/>
        }
    }

    getMenuItems() {
        // @ts-ignore
        const gridDefinition = this.gridDefinition;
        if (gridDefinition.actions) {
            // @ts-ignore
            let menuItems = gridDefinition.actions.map((action, index) => {
                return {
                    label: <React.Fragment>
                                <div className="icon-label">
                                    {this.getIcon(action.command)}
                                    <div className="action-label">{action.label}</div>
                                </div>
                            </React.Fragment>,
                    icon: action.icon,
                    action: action,
                    command: (e: any) => {
                        const rowData = this.gearMenu.current.state.rowData;
                        this.props.model['subfile'][rowData.index]['recordSelected'] = 'Y';
                        this.onActionButtonClick(e.item.action.command);
                    }
                }
            });
            // @ts-ignore
            if (this.selectedRows && this.selectedRows.length > 1 ){
                menuItems = [{
                    // @ts-ignore
                    label: `Bulk Edit (${this.selectedRows.length} items)`,
                    items: menuItems
                }]
            }
            console.log(menuItems);
            return menuItems;
        }
        return null;
    }


    getCellFilter(filter: any, index: any):any{
        if(this.props.model['fields'][filter.field]!==undefined){
            if(filter.type==='string' || filter.type==='long' || filter.type==='number')
            {
                const keyFilter = (filter.type==='number')?'num':'';
                const textAlign =(filter.type==='number')?'right':'left';
                return(
                    <React.Fragment key={'filter'+index}>
                        {filter.label && filter.label!=='' && 
                        (<label className="filter-label"></label>)}
                        <InputText
                        key={'InputText'+ index}
                        id={filter.field}
                        name={filter.field}
                        placeholder={filter.placeholder}
                        value={this.props.model['fields']['filter.field']}
                        keyfilter={keyFilter}
                        onChange={(e:any):void =>{
                            let newValue = e.target.value;
                            if(filter.displayFormat && filter.displayFormat.textCase
                                &&filter.displayFormat.textCase.toLowerCase()==='uppercase')
                                {
                                    newValue = newValue.toUpperCase();
                                }
                                this.onFilterValueChange(newValue , filter.field);
                        }}
                        style={{textAlign}}

                        ></InputText>
                    </React.Fragment>
                );
            }
        }
        return super.getCellFilter(filter, index);
    }


    /*
     * Return the current sortField if one is set.
     */
    getSortField(): string | null {
        try {
            const sortBy = JSON.parse(this.props.model['sortData']);
            const attributes = Object.keys(sortBy);
            return "rcd."+attributes[0];
        } catch (e) { }
        return null;
    }

    /*
     * Return the current sortOrder if one is set.
     */
    getSortOrder(): number | null {
        try {
            const sortBy = JSON.parse(this.props.model['sortData']);
            const attributes = Object.keys(sortBy);
            const order = sortBy[attributes[0]];
            return order === "ASC" ? 1 : -1;
        } catch (e) { }
        return null;
    }

    /*
     * Return true if field is listed in DTO fieldConditions as "sort.XXX"
     */
    isSortable(col:any): boolean {
        return this.props.model
            && this.props.model['fieldConditions']
            && this.props.model['fieldConditions']['sort.'+col.field] === true;
    }

}
