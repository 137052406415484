import * as React from 'react';
import './snapshot.css';
import { tsConstructorType } from '@babel/types';
import { getProgramUrl } from '../../util/OptumPrograms';
import { History } from "history";
import OptumTerminalCommunicationService from "../../util/OptumTerminalCommunicationService";
import { debug } from 'webpack';
import { getSessionId, getAuthType, getTenantId } from '../../security/AuthProxy'

declare global {
  interface Window { config: any; }
}

/**
 *
 */
export interface SnapshotProps {
  type:string;
  params:any;
}


/**
 *
 */
class Snapshot<P = {}, S = any> extends React.Component<SnapshotProps, any> {
  public npiDetailsPrescriberId;
  backendUrl: string;
  terminal: OptumTerminalCommunicationService;
  constructor(props: SnapshotProps) {
    super(props);
    this.terminal = new OptumTerminalCommunicationService();
    this.getData();
        let data = {
          "0": { "0": 0 }
        };
    this.state = {
        data: data,
        isExpanded: false,
        expandedId: []
    };
  }
 
  showLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.add('show');
    }
  }

  hideLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.remove('show');
    }
  }

  /**
   * Calls backend to get data to populate table
   */
  getData() {
    this.showLoader();
    this.backendUrl = window.config['backend'] ? window.config['backend']['backendUrl'] : '';
    fetch(this.backendUrl+'/snapshot/prescriber?prescriberKey='+this.props.params['rcd.phyPrescriberKey'],
        { method: 'POST', 
          headers: { 
              'Accept': 'application/json', 
              'Content-type': 'application/json',
              'Session-ID': decodeURIComponent(getSessionId()) as string,
              'Auth-Type': decodeURIComponent(getAuthType()) as string,
              'tenant-id': decodeURIComponent(getTenantId()) as string
            }, 
          mode: 'cors',
          body: JSON.stringify({})
        })
        .then(response => response.json())
        .then(data => this.setState({data: data, isExpanded: false}))
        .then(data => this.hideLoader())
  }

  /**
   * returns params to route between screens
   * @param programName 
   */
  getParams(programName: string) {
    if(programName == "RCPRF012") {
      let params = [this.props.params['rcd.wrkPhyFormattedName'], this.props.params['rcd.phyPrescriberKey'], this.props.params['rcd.prfPrescriberId'],
      this.props.params['rcd.prfPrescriberIdState'], this.props.params['rcd.prqQualAbbreviation'], this.props.params['rcd.prfVendorKey'], true];
      return params;
    }
    else if(programName == "RCPSP001") {
      let params = [this.props.params['rcd.wrkPhyFormattedName'], this.props.params['rcd.phyPrescriberKey'], this.props.params['rcd.prfPrescriberId'],
      this.props.params['rcd.prfPrescriberIdState'], this.props.params['rcd.prqQualAbbreviation'], true];
      return params;
    }
    else if(programName == "RCPHZ001") {
      let params = [this.props.params['rcd.wrkPhyFormattedName'], this.props.params['rcd.phyPrescriberKey'], this.props.params['rcd.prfPrescriberId'],
      this.props.params['rcd.prfPrescriberIdState'], this.props.params['rcd.prqQualAbbreviation'], true];
      return params;
    }
    else if(programName == "RCPH2001") {
      let params = [this.npiDetailsPrescriberId, this.props.params['rcd.prfPrescriberIdState'],
       this.props.params['rcd.prqPrscrbrIdQualifier'], true];
      
      return params;
    }
    else if(programName == "RCPSR015") {
      let params = [this.props.params['rcd.wrkPhyFormattedName'], this.props.params['rcd.phyPrescriberKey'], this.props.params['rcd.prfPrescriberId'], 
      this.props.params['rcd.prfPrescriberIdState'], this.props.params['rcd.prqPrscrbrIdQualifier'],true];
      return params;
    }
    else if(programName == "RCADR001") {
      let params = [this.props.params['rcd.phyPrescriberKey']];
      return params;
    }
  }

  /**
   * redirect to screens from snapshot
   * @param programName 
   */
  redirect(programName: string) {
    window.parent.postMessage(
    {
      message: "navigate-to-program-internal",
      programCode: programName,
      parameters : this.getParams(programName)
    }, '*')
  }

  isActiveAllowedDrugSchedule(prescriberId) {
    let list = this.state.data.prescriberIdDTO;
    for(let presc in list) {
      if(list[presc].prfPrescriberId == prescriberId) {
        if(list[presc].adrAlowDrugSchdFordeaDTO.length != 0) {
          return true;
        }
      }
    }
    return false;
  }

  getActiveAllowedDrugScheduleView(prescriberId) {
    let list = this.state.data.prescriberIdDTO;
    let items = [];

    if(list != undefined) {
      for(let i in list) {
        if(list[i].prfPrescriberId == prescriberId) {
          let prescriber = list[i];
          items = prescriber.adrAlowDrugSchdFordeaDTO.map(deaList =>
            <tr>
              <td>{deaList.adrType}</td>
              <td>{deaList.adrDesc}</td>
            </tr>
          )
          break;
        }
      }
    }
    if(items.length == 0) {
      items.push(<div className="text">No Data Found</div>)
      return (
        <div>{items}</div>
      )
    } else {
      return (
        <React.Fragment>
          <table className="display">
            <tbody>
              <tr>
                <td>
                  <strong>Type</strong>
                </td>
                <td>
                  <strong>Description</strong>
                </td>
              </tr>
              {items}
            </tbody>
          </table>
        </React.Fragment>
      )
    }
  }

  medEnrollmentView() {
    let list = this.state.data.prescriberIdDTO;
    let items = [];
    if(list != undefined) {
      items = list.flatMap(en => 
        en.prescriberEnrollmentDTO.map(enrollList => 
          <React.Fragment>
            <table className="display">
              <tbody>
                <tr>
                  <td><strong>From Date</strong></td>
                  <td>{enrollList.mefEffectiveDate}</td>
                </tr>
                <tr>
                  <td><strong>Opt Out Flag</strong></td>
                  <td>{enrollList.mefOptOutFlag}</td>
                </tr>
                <tr>
                  <td><strong>Update Date</strong></td>
                  <td>{enrollList.mefUpdateDate}</td>
                </tr>
                <tr>
                  <td><strong>Update Flag</strong></td>
                  <td>{enrollList.mefUpdateFlag}</td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        )
      )
    }
    if(items.length == 0){
      items.push(<div className="text">No Data Found</div>)
    }
    return <div>{items}</div>
  }

  npiDetailsView() {
    let list = this.state.data.prescriberIdDTO;
    let items = [];
    if(list != undefined){
      for(let i in list){
        if(list[i].prqPrscrbrIdQualifier == "NPI"){
          this.npiDetailsPrescriberId = list[i].prfPrescriberId;
        }
      }
      items = list.flatMap(npi => 
        npi.ph2NpiDetailsDTO.map(npiList =>
          <React.Fragment>
            <table className="display">
              <tbody>
                <tr>
                  <td><strong>Deactivation Code</strong></td>
                  <td>{npiList.ph2DeactivationCode}</td>
                </tr>
                <tr>
                  <td><strong>Deactivation Date</strong></td>
                  <td>{npiList.ph2DeactivationDate}</td>
                </tr>
                <tr>
                  <td><strong>Reactivation Date</strong></td>
                  <td>{npiList.ph2ReactivationDate}</td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        )
      )
    }

    if(items.length == 0){
      items.push(<div className="text">No Data Found</div>)
    }
    return <div>{items}</div>
  }

  onExpand(prescriberId) {
    if(this.state.expandedId.indexOf(prescriberId) > -1){
      let newList = this.state.expandedId
      newList.splice(this.state.expandedId.indexOf(prescriberId), 1)
      this.setState({isExpanded: false, expandedId: newList})
    }else{
      let newList = this.state.expandedId;
      newList.push(prescriberId);
      this.setState({isExpanded: true, expandedId: newList})
    }
  }

  prescriberListView() {
    let list = this.state.data.prescriberIdDTO;
    let items = [];
    if(list != undefined) {
      items = list.map(presc =>
        <React.Fragment>
          <tr className="expand-row" onClick={() => this.onExpand(presc.prfPrescriberId)}>
            {this.isActiveAllowedDrugSchedule(presc.prfPrescriberId) && 
              this.state.expandedId.indexOf(presc.prfPrescriberId) > -1 ? 
                <td>▼</td>: <td>►</td>
            } 
            <td>{presc.prfPrescriberId}</td>
            <td>{presc.prfPrescriberIdState}</td>
            <td>{presc.prqPrscrbrIdQualifier}</td>
            <td>{presc.adhAllowedDeaSchedHdrDTO.renewalDate}</td>
          </tr>
          
          {this.isActiveAllowedDrugSchedule(presc.prfPrescriberId) && 
          this.state.expandedId.indexOf(presc.prfPrescriberId) > -1 && (
            <tr>
              <td colSpan={5}>
                {this.getActiveAllowedDrugScheduleView(presc.prfPrescriberId)}
              </td>
            </tr>
          )}
        </React.Fragment>
      )
    }
    return (
      <table className="display">
        <tbody> 
          <tr>
            <td></td>
            <td>
              <strong>Prescriber Id:</strong>
            </td>
            <td>
              <strong>State:</strong>
            </td>
            <td>
              <strong>Qualifier:</strong>
            </td>
            <td>
              <strong>Expired Date:</strong>
            </td>
          </tr>
          {items}
        </tbody>
      </table>
    )
  }

  getTaxonomyList(){
    let list = this.state.data.phzTaxonomyDTO
    let items = [];
    if(list != undefined){
      items = list.map(tax => 
        <tr>
          <td>{tax.htcTaxonomyCode}</td>
          <td>{tax.phzTaxonomyDescription}</td>
        </tr>
      )
    }
    if(items.length == 0) {
      items.push(<div className="text">No Data Found</div>)
      return (
        <div>{items}</div>
      )
    } else {
      return (
        <table className="display">
          <tbody>
            <tr>
              <td>
                <strong>Code</strong>
              </td>
              <td>
                <strong>Description</strong>
              </td>
            </tr>
            {items}
          </tbody>
        </table>
      )
    }
  }

  getSpecialtyList(){
    let list = this.state.data.pspPrescriberSpecialtyDTO;
    let items = [];
    if(list != undefined) {
      items = list.map(ds =>
        <tr>
          <td>{ds.dsCode}</td>
          <td>{ds.dsDescription}</td>
        </tr>
      )
    }
    if(items.length == 0){
      items.push(<div className="text">No Data Found</div>)
      return (
        <div>{items}</div>
      )
    } else {
      return (
        <table className="display">
          <tbody>
            <tr>
              <td>
                <strong>Code</strong>
              </td>
              <td>
                <strong>Description</strong>
              </td>
            </tr>
            {items}
          </tbody>
        </table>
      )
    }
  }

  getSanctionList(){
    let list = this.state.data.psrPrescriberSanctionDTO;
    let items = [];
    if(list != undefined) {
      items = list.map(sanction => 
        <tr>
          <td>{sanction.psrSanctionDate}</td>
          <td>{sanction.psrSanctionReinstDte}</td>
          <td>{sanction.psrSanctionSource}</td>
        </tr>
      )
    }
    if(items.length == 0){
      items.push(<div className="text">No Data Found</div>)
      return (
        <div>{items}</div>
      )
    }
    return (
      <table className="display">
          <tbody>
            <tr>
              <td>
                <strong>Date</strong>
              </td>
              <td>
                <strong>Reinstallment Date</strong>
              </td>
              <td>
                <strong>Sanction Source</strong>
              </td>
            </tr>
            {items}
          </tbody>
        </table>
    )
  }

  getPrescriberSnapshot(): React.ReactElement {
    return (
      <React.Fragment>
        <hr></hr>
        <div className="p-grid">
          <div className="p-col">
            <strong>Prescriber Name: </strong>
            {this.state.data.phyLastName}, {this.state.data.phyFirstName} {this.state.data.phyMiddleInitial} {this.state.data.phyNameSuffix}
            <span className="deaCode">
                <strong>DEA Code: </strong>
                {this.state.data.phyDeaCode}
            </span>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col">
            <a onClick={() => this.redirect("RCPRF012")}><h5>Prescriber Info</h5></a>
            {this.prescriberListView()}
          </div>
          <div className="p-col">
            <a onClick={() => this.redirect("RCPHZ001")}><h5>Taxonomy Info</h5></a>
            {this.getTaxonomyList()}
            <a onClick={() => this.redirect("RCPSR015")}><h5>Sanction Master</h5></a>
            {this.getSanctionList()}
          </div>
          <div className="p-col">
            <a onClick={() => this.redirect("RCPSP001")}><h5>Specialty Info</h5></a>
            {this.getSpecialtyList()}
            <a onClick={() => this.redirect("RCPH2001")}><h5>NPI Details</h5></a>
            {this.npiDetailsView()}
          </div>
        </div>
      </React.Fragment >
    )
  }

  /**
   *
   */
  render(): React.ReactElement {
    let snapData = this.state.data;
    if(this.props.type == "prescriber"){
      return this.getPrescriberSnapshot();
    }
  }
}

export default Snapshot;
