import * as React from 'react';
import { TerminalCommunicationService } from '@fresche/terminal-lib';
import AppHeader from '../../layout/app-header/AppHeader';
import AppFooter from '../../layout/app-footer/AppFooter';
import BaseComponent from '../base';
import BasePopup from '../popup/base-popup';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

declare global {
  interface Window { config: any; }
}

/**
 *
 */
class BaseView<P = {}, S = any> extends React.Component<any, any> {
  protected terminal = new TerminalCommunicationService();
  public funKeyPos: string;
  public screenId: string;
  protected formData: any;
  protected screenDef: any;
  protected popupFormData: any;
  protected popupScreenDef: any;
  protected displayDialog: boolean;


  /**
   *
   */
  constructor(props: any, screenName: string, isDialog: boolean, isMultiPage?: boolean) {
    super(props);
    this.funKeyPos = window.config['ui'] ? window.config['ui']['funKeyPos'] : 'top';

    this.state = {
      displayConfirmation: false
    };

    this.screenId = null;
    this.formData = null;
    this.screenDef = null;
    this.popupFormData = null;
    this.popupScreenDef = null;
    this.displayDialog = false;

    this.onHideDialog = this.onHideDialog.bind(this);
    this.handleConfirmationKeys = this.handleConfirmationKeys.bind(this);
  }

  /**
   *
   */
  componentDidUpdate(): void {
    this.initialize();

    if (window.location.pathname.endsWith("/main/mainMenu")
        && window.self !== window.top
        && window.self.name === "main-iframe"){
      // FIXME : temporary code to manage iframe "iframe-provider-test". close main menu.
      const url = window.location !== window.parent.location ? document.referrer : document.location.href;
      window.parent.postMessage("close-iframe", '*');
    }
  }

  /**
   *
   */
  componentDidMount(): void {
    document.addEventListener('keydown', this.handleConfirmationKeys);
  }

  /**
   *
   */
  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleConfirmationKeys);
  }

  /**
   *
   */
  initialize(): void {
    if (!this.isMasterMenu()) {
      const locationState = this.props.location.state;

      if (locationState && locationState.screenId && locationState.formData) {
        if ((locationState.screenId === this.screenId && this.displayDialog === false) && (JSON.stringify(locationState.formData) === JSON.stringify(this.formData))) {
          return;
        }

        if ((locationState.screenId === this.screenId && this.displayDialog === true) && (JSON.stringify(locationState.formData) === JSON.stringify(this.popupFormData))) {
          return;
        }

        this.screenId = locationState.screenId;

        if (locationState.screenId === '_screenConfirm') {
          if (locationState.formData) {
            this.hideLoader();
            this.formData = locationState.formData;
            this.setState({ displayConfirmation: true });
          }
        } else if (locationState.screenId) {
          const path = `./screenDefinitions/${locationState.screenId.toLowerCase()}.component.json`;
          fetch(path)
            .then((r: any): any => {
              return r.json();
            })
            .then((result: any): any => {
              this.hideLoader();
              if (!locationState.formData.modalScreen) {
                this.formData = locationState.formData;
                this.screenDef = result;
                this.displayDialog = false;
              } else {
                this.popupFormData = locationState.formData;
                this.popupScreenDef = result;
                this.displayDialog = true;
              }
              this.forceUpdate();
            });
        }
      } else {
        this.hideLoader();
      }
    }
  }

  /**
   *
   */
  handleConfirmationKeys(event: KeyboardEvent): void {
    if (this.state.displayConfirmation === true) {
      if (event.code === 'KeyY') {
        this.onHideDialog('displayConfirmation', 'Y');
      } else if (event.code === 'KeyN') {
        this.onHideDialog('displayConfirmation', 'N');
      }
    }
  }

  /**
   * Stops the loaders.
   */
  hideLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.remove('show');
    }
  }

  /**
   * Detect if the screen is the current displayed
   */
  isActiveScreen(): boolean {
    if (this.terminal && this.terminal.getCurrentScreen()) {
      let terminalCurrentScreen = this.terminal.getCurrentScreen().toLowerCase();
      let screenName = window.location.pathname.toLowerCase();
      const basedir = window.config['app']['basedir'] === '/' ? '' : window.config['app']['basedir'];
      terminalCurrentScreen = basedir + terminalCurrentScreen;

      // Remove slashes
      terminalCurrentScreen = terminalCurrentScreen.replace(/\//g, '');
      screenName = screenName.replace(/\//g, '');

      return terminalCurrentScreen === screenName;
    }
    return false;
  }

  isMasterMenu(): boolean {
    const screenName = this.terminal.getCurrentScreen();

    if (screenName && screenName.toLowerCase().indexOf('mastermenu') !== -1) {
      return true;
    }
    return false;
  }

  /**
   * On hide dialog
   */
  onHideDialog(name: any, choice: any): any {
    if (this.displayDialog) {
      const cloneFormData = this.popupFormData;
      cloneFormData['confirm'] = choice;
    } else {
      const cloneFormData = this.formData;
      cloneFormData['confirm'] = choice;
    }

    this.terminal.reply();
    this.setState({
      [`${name}`]: false
    });
  }

  /**
   * Render the dialog footer
   */
  renderDialogFooter(name: any): any {
    return (
      <div>
        <Button label="No" icon="pi pi-times" onClick={(): any => this.onHideDialog(name, 'N')} className="f-confirm-dialog" />
        <Button label="Yes" icon="pi pi-check" onClick={(): any => this.onHideDialog(name, 'Y')} autoFocus={true} className="f-cancel-dialog" />
      </div>
    );
  }

  renderPage(): React.ReactElement {
    if (this.screenDef && this.formData) {
      return (
        <React.Fragment>
          <AppHeader formData={this.formData} screenDef={this.screenDef} /> 
          {this.renderContent()}
          <AppFooter userName={this.toTitleCase((this.formData['givenName'] === undefined ? '' : this.formData['givenName']) + ' ' + (this.formData['familyName'] === undefined ? '' : this.formData['familyName']))} screenDef={this.screenDef}/>
        </React.Fragment>
      );
    }
  }

  toTitleCase(userName: any) {
    return userName.replace(/\w\S*/g, function(txt: any){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  renderContent(): React.ReactElement {
    if (this.screenDef && this.formData) {
      return (<BaseComponent type="view" screenDef={this.screenDef} formData={this.formData} isActiveScreen={!this.displayDialog} />);
    }
  }

  renderConfirmDialog(): React.ReactElement {
    return (
      <Dialog
        className='confirm-dialog'
        visible={this.state.displayConfirmation}
        modal={true}
        closable={false}
        footer={this.renderDialogFooter('displayConfirmation')}
        blockScroll={true}
        onHide={(): any => this.onHideDialog('displayConfirmation', 'N')}>
        <div className="confirmation-content">
          <span>Confirmation</span>
        </div>
      </Dialog>
    );
  }

  renderPopupScreen(): React.ReactElement {
    if (this.displayDialog && this.popupScreenDef && this.popupFormData) {
      return (
        <Dialog
          header={this.popupScreenDef.title + ' - ' + this.popupScreenDef.programName}
          visible={this.displayDialog}
          modal={true}
          closable={false}
          closeOnEscape={false}
          baseZIndex={1250}
          style={{ width: 'auto' }}
          onHide={(): void => { }}>
          {this.renderPopupContent()}
          <span className="help-button" onClick={() => this.openHelpText(this.popupScreenDef.programName)}>
            Help
          </span>
        </Dialog>
      );
    }
  }

  renderPopupContent(): React.ReactElement {
    return <BasePopup screenDef={this.popupScreenDef} formData={this.popupFormData} />
  }

  openHelpText(programName:any) {
    window.parent.postMessage(
      {
        message: "sendProgramName",
        programName: programName,
        application: "Prescriber"
      }, '*');
  }

  /**
   *
   */
  render(): React.ReactElement {
    const contentStyle = {
      marginBottom: this.funKeyPos && this.funKeyPos === 'bottom' ? '98px' : '50px',
      marginTop: '0'
    };
    return (
      <React.Fragment>
        {this.renderConfirmDialog()}
        <main style={contentStyle}>
          {this.renderPage()}
        </main>
        {this.renderPopupScreen()}
      </React.Fragment >
    );
  }
}

export default BaseView;
