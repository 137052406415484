import * as React from 'react';
import { model, TerminalCommunicationService } from '@fresche/terminal-lib';
import { FContinueButton, FNumericInput, FSelectInput, FTextInput, FLabel, FDateTimeInput, FMultilineInput, FGrid } from '@fresche/ui-lib-react';
import { MegaMenu } from 'primereact/megamenu';
import { Tooltip } from 'primereact/tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextDisplay from '../layout/text-display/text-display';
import './base.css';
import OptumGrid from "../layout/ui/OptumGrid";

declare global {
  interface Window { config: any; }
}

export interface BaseProps {
  type?: string;
  formData: any;
  screenDef: any;
  isActiveScreen: boolean;
}

/**
 *
 */
class BaseComponent<P = {}, S = any> extends React.Component<BaseProps, any> {
  protected terminal = new TerminalCommunicationService();

  public inputErrors: any;

  public dateFormat: string;
  public timeSeparator: string;
  public showSeconds = true;
  public funKeyPos: string;
  public legacyMode: boolean;

  public conditions: any = {};

  public formatedInputs: any[];
  public selectedActionField = 'selected';

  protected focusedField = '';
  protected focusedFieldId = '';
  protected tablerowFocusedField = -1; // 0 = input, >0 = datarow

  protected dataGrid: React.RefObject<FGrid> = React.createRef();
  public keysNavigation = React.createRef();
  protected screenDef: any;
  protected formData: any;
  private isProtected = false;

  public functionKeys: any;
  public menu: MegaMenu; // = React.createRef();

  /**
   *
   */
  constructor(props: BaseProps) {
    super(props);

    this.dateFormat = window.config['ui'] ? window.config['ui']['dateFormat'] : 'mm/dd/yy';
    this.timeSeparator = window.config['ui'] ? window.config['ui']['timeSeparator'] : ':';
    this.showSeconds = window.config['ui'] ? window.config['ui']['showSeconds'] : true;
    this.funKeyPos = window.config['ui'] ? window.config['ui']['funKeyPos'] : 'top';
    this.legacyMode = window.config['ui'] ? window.config['ui']['legacyMode'] : false;

    this.handleKeyboardEvents = this.handleKeyboardEvents.bind(this);
    this.initErrorMessages = this.initErrorMessages.bind(this);
    this.tableAction = this.tableAction.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.screenDef = this.props.screenDef;
    this.formData = this.props.formData;

    // Split inputs by rows
    this.formatedInputs = [];
    if (props.screenDef.inputs && props.screenDef.inputs.length) {
      this.formatedInputs = this.formatInputs(props.screenDef.inputs);
    }
    this.inputErrors = {};

    this.initErrorMessages(this.props);
    this.setConditions(this.props);
    this.setDefaultCursor();

    this.functionKeys = [];
    if (props.screenDef.functionKeys && props.screenDef.
      functionKeys.length) {
      this.functionKeys = this.formatFunctionKeys();
    }
  }

  /**
   *
   */
  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyboardEvents, false);
  }

  /**
   *
   */
  componentDidUpdate(): void {
    // When we pass from a popup to a view
    if (JSON.stringify(this.screenDef) !== JSON.stringify(this.props.screenDef)) {
      this.formatedInputs = [];
      if (this.props.screenDef.inputs && this.props.screenDef.inputs.length) {
        this.formatedInputs = this.formatInputs(this.props.screenDef.inputs);
      }

      this.setDefaultCursor();
      this.setConditions(this.props);
      this.functionKeys = [];

      if (this.props.screenDef.functionKeys && this.props.
        screenDef.functionKeys.length) {
        this.functionKeys = this.formatFunctionKeys();
      }

      this.screenDef = this.props.screenDef;

      this.forceUpdate();
    } else {
      let needRefreshState = false;
      let needRefreshForError = false;

      // Because we have a dynamic nested object, we compare each node valud
      if (JSON.stringify(this.formData.fields) !== JSON.stringify(this.props.formData.fields)) {
        needRefreshState = true;
      }

      if (JSON.stringify(this.formData['messageDTO']) !== JSON.stringify(this.props.formData['messageDTO'])) {
        needRefreshForError = true;
        needRefreshState = true;
      }

      if (JSON.stringify(this.formData.portunusVerb) !== JSON.stringify(this.props.formData.portunusVerb)) {
        needRefreshState = true;
      }

      if (this.formData['programMode'] !== this.props.formData['programMode']) {
        needRefreshState = true;
      }

      if (this.formData.subfile && this.props.formData.subfile) {
        for (let index = 0; index < this.props.formData.subfile.length; index++) {
          const propsFields = JSON.stringify(this.props.formData.subfile[index].fields);
          const stateFields = this.formData.subfile[index] ? JSON.stringify(this.formData.subfile[index].fields) : '';

          const propsErrors = JSON.stringify(this.props.formData.subfile[index].messageDTO);
          const stateError = this.formData.subfile[index] ? JSON.stringify(this.formData.subfile[index].messageDTO) : '';

          if (propsFields !== stateFields) {
            needRefreshState = true;
          }

          if (propsErrors !== stateError) {
            needRefreshForError = true;
          }
        }
        // FIX active/inactive reloading issues.
        if (this.formData.subfile.length !== this.props.formData.subfile.length) {
          needRefreshState = true;
        }
      }
      if (needRefreshForError) {
        this.initErrorMessages(this.props);
      }

      if (needRefreshState) {
        this.formData = this.props.formData;
        this.setDefaultCursor();
        this.functionKeys = [];
        if (this.props.screenDef.functionKeys && this.props.
          screenDef.functionKeys.length) {
          this.functionKeys = this.formatFunctionKeys();
        }
        this.setConditions(this.props);

        this.forceUpdate();
      }
    }
  }

  /**
   *
   */
  componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyboardEvents, false);
    this.setDefaultCursor();
  }

  /**
   *
   */
  setDefaultCursor(): void {
    setTimeout((): void => {

      // TO REVISE
      /*
      if (self.dataGrid && self.dataGrid.autoFocus && self.state.formData && self.state.formData['cursorField'] && self.state.formData['cursorTableRow'] >= 0) {
        // self.dataGrid.setFocus(self.gridSelectors, self.gridCells, self.gridFilters);
      }*/

      const selectors = [
        'input:not([disabled]):not([readonly]):not([tabindex="-1"])',
        'select:not([disabled]):not([readonly]):not([tabindex="-1"])',
        'textarea:not([disabled]):not([readonly]):not([tabindex="-1"])'
      ];

      let rootToSearch: any = document;

      // if we are in a popup dialog then we need to search inside this dialog (not in all DOM)
      if (this.props.type === 'popup') {
        rootToSearch = document.querySelector('div.p-dialog');
      }

      const focusables = rootToSearch.querySelectorAll(selectors.join(','));

      if (focusables.length > 0) {
        let toFocus: any = focusables[0];

        // Check if the control is the SELECT ALL in the grid
        let currentParent = toFocus.parentNode;
        let isSelectAllCheckBox = false;
        while (currentParent !== null && !isSelectAllCheckBox) {
          if (currentParent.className && currentParent.className.indexOf('p-selection-column') > -1) {
            isSelectAllCheckBox = true;
          }
          currentParent = currentParent.parentNode;
        }

        if (isSelectAllCheckBox) {
          if (focusables.length > 1) {
            toFocus = focusables[1];
          }
        }
        toFocus.focus();
      }
    }, 1);
  }

  /**
   * initialize the view
   */
  initErrorMessages(props: any): void {
    if (props.formData['messageDTO']) {
      this.handleMessages(props.formData['messageDTO']);
    }

    // validates grid field conditions
    if (props.formData['subfile'] && props.formData['subfile'].length > 0) {
      for (let i = 0; i < props.formData['subfile'].length; i++) {
        const rowData = props.formData['subfile'][i];
        if (rowData['messageDTO']) {
          this.onGridError(rowData['messageDTO']);
        }
      }
    }
  }

  /**
   * Handles Keyboard Events
   */
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (this.props && this.screenDef && this.props.isActiveScreen) {
      // Handles function keys
      if (this.screenDef.functionKeys) {
        const keyCode = event.shiftKey ? event.key + ' - shift' : event.key;

        const key = this.screenDef.functionKeys.filter((x: any): any => x.key === keyCode)[0];
        if (key !== undefined) {
          event.preventDefault();
          this.defaultAction(key.command);
        } else if (keyCode === 'F4') {
          event.preventDefault();
          this.defaultAction('04');
        } else if (keyCode === 'F12') {
          event.preventDefault();
        }
      }

      // Handles multipage
      switch (event.key) {
        case 'PageUp':
          if (this.screenDef.isMultiPage) {
            event.preventDefault();
            event.stopPropagation();
            this.pageChanged('previous');
          }
          break;
        case 'PageDown':
          if (this.screenDef.isMultiPage) {
            event.preventDefault();
            event.stopPropagation();
            this.pageChanged('next');
          }
          break;
        default:
          break;
      }

    }
  }

  /**
   * Message handler - generate msg for each one contained in the DTO
   */
  handleMessages(msgs: any): void {
    this.inputErrors = {};

    // const messages: { severity: any; summary: any; detail: any; }[] = [];
    msgs.forEach((e: any): void => {
      // map every error message to a given field name
      if (e.fieldsName && e.fieldsName.length > 0) {
        e.fieldsName.forEach((field: any): void => {
          if (Object.keys(this.props.formData.fields).indexOf(field) > -1) {
            this.inputErrors[field] = e.messageText;
          }
        });
      } else {
        const msg: any = {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        };
        switch (e.messageType) {
          case 'SNDERRMSG':
            toast.error(e.messageText, msg);
            break;
          case 'SNDINFMSG':
          case 'SNDSTSMSG':
            toast.info(e.messageText, msg);
            break;
          case 'SNDCMPMSG':
            toast.success(e.messageText, msg);
            break;
          default:
            toast.info(e.messageText, msg);
            break;
        }
      }
    });
  }

  // ================================================================
  //  CONDITIONS
  // ================================================================

  /**
   * Sets the conditions object for the current screen.
   */
  setConditions(props: any): void {
    this.manageConditions(props, 'inputs', 'field');
    this.manageConditions(props, 'functionKeys', 'key');
    this.manageGridConditions(props);
    if (this.props.formData['authorised']) {
      this.manageAuthorization(props);
    }
    if (this.screenDef.hasContinueButton !== true)
      this.screenDef.hasContinueButton = this.props.formData['hasContinueButton'];
  }

  /**
   * Hide the F6/F11/2/4 buttons and if user has access then it will be enabled
   * @param props 
   */
  manageAuthorization(props: any) {
    var editOption = {};
    let editIndex = 0;
    var deleteOption = {};
    let deleteIndex = 0;
    let actionButtonToBeRemoved = [];
    if (this.conditions['functionKeys']["F6"]) this.conditions['functionKeys']["F6"]['hidden'] = true;
    if (this.conditions['functionKeys']["F11"]) this.conditions['functionKeys']["F11"]['hidden'] = true;
    if (props.screenDef.gridDefinition && props.screenDef.gridDefinition.actions) {
      for (let index = 0; index < props.screenDef.gridDefinition.actions.length; index++) {
        if (props.screenDef.gridDefinition.actions[index]['key'] == "2") {
          editOption = props.screenDef.gridDefinition.actions[index];
        } else if (props.screenDef.gridDefinition.actions[index]['key'] == "4") {
          deleteOption = props.screenDef.gridDefinition.actions[index];
        }
        for (let index1 = 0; index1 < this.formData['removeActionButtons'].length; index1++) {
          if (props.screenDef.gridDefinition.actions && props.screenDef.gridDefinition.actions[index] &&
            props.screenDef.gridDefinition.actions[index]['key'] === this.formData['removeActionButtons'][index1]) {
            actionButtonToBeRemoved.push(props.screenDef.gridDefinition.actions[index]);
          }
        }
      }
      editIndex = props.screenDef.gridDefinition.actions.indexOf(editOption);
      if (editIndex >= 0) props.screenDef.gridDefinition.actions.splice(editIndex, 1);
      deleteIndex = props.screenDef.gridDefinition.actions.indexOf(deleteOption);
      if (deleteIndex >= 0) props.screenDef.gridDefinition.actions.splice(deleteIndex, 1);

      actionButtonToBeRemoved.forEach(actionButton => {
        var actionButtonINdex = props.screenDef.gridDefinition.actions.indexOf(actionButton);
        if (actionButtonINdex >= 0) {
          props.screenDef.gridDefinition.actions.splice(actionButtonINdex, 1);
        }
      });
    }
    if (this.props.formData['portunusVerb']) {
      if (this.props.formData['portunusVerb']['INSERT'] === 'GRANT') {
        if (this.conditions.length === 0) {
          this.conditions['functionKeys'] = {};
        }
        if (this.conditions['functionKeys'] && this.conditions['functionKeys']["F6"])
          this.conditions['functionKeys']["F6"]['hidden'] = false;
      }
      if (this.props.formData['portunusVerb']['CHMODE'] === 'GRANT') {
        if (this.props.formData['programMode'] === 'DSP') {
          props.screenDef.functionKeys.splice(1, 0, { "key": "chmode", "command": "chmode", "label": "Edit" });
        } else {
          props.screenDef.functionKeys.splice(1, 0, { "key": "chmode", "command": "chmode", "label": "View" });
        }
      }
      if (this.props.formData['portunusVerb']['DELETE'] === 'GRANT') {
        if (this.conditions.length === 0) {
          this.conditions['functionKeys'] = {};
        }
        if (this.conditions['functionKeys'] && this.conditions['functionKeys']["F11"]) {
          this.conditions['functionKeys']["F11"]['hidden'] = false;
        }
        if (deleteIndex >= 0 && props.screenDef.gridDefinition
          && props.screenDef.gridDefinition.actions.indexOf(deleteOption) < 0) {
          props.screenDef.gridDefinition.actions.splice(deleteIndex, 0, deleteOption);
        }
      }
      if (this.props.formData['portunusVerb']['MODIFY'] === 'GRANT' && editIndex >= 0
        && props.screenDef.gridDefinition
        && props.screenDef.gridDefinition.actions.indexOf(editOption) < 0) {
        props.screenDef.gridDefinition.actions.splice(editIndex, 0, editOption);
      }
    }
  }

  /**
   * Apply a condition to a given container if a match is found in the
   * backend's response (formData).
   * @param condition the condition object
   * @param container the object containing the conditions
   */
  applyCondition(props: any, condition: any, container: any): any {
    // Split the conditionField string at every dot found
    if (condition && condition.conditionField) {
      if (this.isActiveCondition(props.formData, condition.conditionField)) {
        // Overwrite/Add the value to the conditions object (initial display)
        container[condition.attribute] = condition.attributeValue;
      }
    }
  }

  /**
   * Finds the condition value in the model.
   * @param obj object in which the condition must be found
   * @param path the path provided that leads to the condition
   */
  isActiveCondition(obj: any, path: string): boolean {
    const p = path.split('.');
    if (p.length > 1) {
      // Keep first index
      const accessor = p[0];
      // If accessor is found in the object
      if (obj[accessor]) {
        // Remove first index from path
        p.shift();
        return obj[accessor][p.join('.')];
      } else {
        // console.error(`Condition path not found in the model: ${path}`);
        return false;
      }
    }
    // console.error(`Condition path not found in the model: ${path}`);
    return false;
  }

  /**
   * Constructs a condition object for a specific element type.
   * {
   *   inputs: {
   *     inputName: {
   *       conditionName: conditionValue
   *     }
   *   }
   * }
   * @param elements The element type (inputs or functionKeys).
   * @param identifier The identifier for the given element.
   */
  manageConditions(props: any, elements: string, identifier: string): void {
    if (elements === 'inputs' || elements === 'functionKeys') {
      if (props.screenDef[elements]) {
        // Initialize the conditions for this element
        this.conditions[elements] = {};
        // Loop every element in the screen
        props.screenDef[elements].forEach((element: any): void => {
          // Initialize an object for this specific element
          this.conditions[elements][element[identifier]] = {};
          // If the element has an initial display
          if (element.initialDisplay) {
            // Loop every display object
            element.initialDisplay.forEach((cond: any): void => {
              // Assign the display value to its attribute in the conditions object
              this.conditions[elements][element[identifier]][cond.attribute] = cond.attributeValue;
            });
          }
          // If the element has display conditions
          if (element.displayConditions) {
            // Loop every conditions
            element.displayConditions.forEach((condition: any): void => {
              this.applyCondition(props, condition, this.conditions[elements][element[identifier]]);
            });
          }
        });
      }
    }
  }

  /**
   * Same function as manageConditions, but targeted for conditions found in the
   * subfile of the DTO provided by the backend.
   */
  manageGridConditions(props: any): void {
    // Initialize the conditions for this grid
    this.conditions.grid = {
      gridSelection: {
        rows: {}
      },
      filters: {}
    };
    if (props.screenDef.gridDefinition) {
      const gridDefinition = props.screenDef.gridDefinition;
      // Selection conditions
      if (gridDefinition.selectionConditions) {
        // Initial display
        if (gridDefinition.selectionConditions.initialDisplay) {
          gridDefinition.selectionConditions.initialDisplay.forEach((c: any): void => {
            this.conditions.grid.gridSelection[c.attribute] = c.attributeValue;
          });
        }
        // Display conditions
        if (gridDefinition.selectionConditions.displayConditions && props.formData.subfile) {
          gridDefinition.selectionConditions.displayConditions.forEach((c: any): any => {
            if (c && c.conditionField) {
              this.applyCondition(props, c, this.conditions.grid.gridSelection);
              // Check for specific row condition
              props.formData.subfile.forEach((row: any, index: number): void => {
                if (this.isActiveCondition(row, c.conditionField)) {
                  const value = c.attributeValue;
                  if (this.conditions.grid.gridSelection.rows[index.toString()] === undefined) {
                    this.conditions.grid.gridSelection.rows[index.toString()] = {};
                  }
                  this.conditions.grid.gridSelection.rows[index.toString()][c.attribute] = value;
                }
              });
            }
          });
        }
      }
      // Retrieve all columns
      const columns = gridDefinition.columns;
      // Loop all columns
      columns.forEach((column: any): void => {
        // Initialize an array of conditions for this specific column
        let columnConditions = {};
        // Loop every cell of a column
        column.forEach((cell: any): void => {
          // Keep for backwards compatibility with f-grid-cell (must have 'editable' property)
          cell.editable = true;
          // Initialize a conditions object for this specific cell
          const cellConditions: any = {
            rows: {}
          };
          // If the column has an initial display
          if (cell.initialDisplay) {
            // Loop every display object
            cell.initialDisplay.forEach((cond: any): void => {
              // Assign the display value to its attribute in the conditions object
              cellConditions[cond.attribute] = cond.attributeValue;
            });
            columnConditions = cellConditions;
          }
          // If the column has display conditions
          if (cell.displayConditions && props.formData.subfile) {
            // Loop every conditions
            cell.displayConditions.forEach((condition: any): void => {
              if (condition && condition.conditionField) {
                this.applyCondition(props, condition, cellConditions);
                // Check for single cell conditions
                props.formData.subfile.forEach((row: any, index: number): void => {
                  if (this.isActiveCondition(row, condition.conditionField)) {
                    const value = condition.attributeValue;
                    if (cellConditions.rows[index.toString()] === undefined) {
                      cellConditions.rows[index.toString()] = {};
                    }
                    cellConditions.rows[index.toString()][condition.attribute] = value;
                  }
                });
              }
            });
          }
          // If cell has filters
          if (cell.filters && cell.filters.length) {
            cell.filters.forEach((filter: any): void => {
              // Create object to store conditions for this filter
              if (this.conditions.grid.filters[filter.field] === undefined) {
                this.conditions.grid.filters[filter.field] = {};
              }
              // Initial display
              if (filter.initialDisplay) {
                filter.initialDisplay.forEach((condition: any): void => {
                  this.conditions.grid.filters[filter.field][condition.attribute] = condition.attributeValue;
                });
              }
              // Display Conditions
              if (filter.displayConditions) {
                filter.displayConditions.forEach((condition: any): void => {
                  this.applyCondition(props, condition, this.conditions.grid.filters[filter.field]);
                });
              }
            });
          }
        });
        this.conditions.grid[column[0].field] = columnConditions;
      });
    }
  }

  /**
   * Returns the value of an attribute for a specific element.
   * @param container object containing conditions
   * @param inputID the ID of the element (field name)
   * @param attribute the condition attribute
   */
  getCondition(container: any, inputID: string, attribute: string): any {
    if (container && container[inputID]) {
      return container[inputID][attribute];
    }
  }

  // ================================================================
  //  TOOLBAR
  // ================================================================

  getToolbarClasses(): string {
    const classes = [];
    if (this.props.formData && this.props.formData.modalScreen) {
      classes.push('popup-keys');
    }
    if (this.funKeyPos === 'bottom') {
      classes.push('bottom');
    }
    return classes.join(' ');
  }

  /**
     * Returns the CSS classes defining the item.
     */
  getItemClasses(item: any): string {
    const classes: string[] = ['col'];

    if (item) {
      if (item.size) {
        classes.push('col-' + item.size);
      }

      if (item.type === 'label' || item.isLabel) {
        classes.push('label-col');
      }

      if (!item.label) {
        classes.push('no-label');
      }

      if (item.displayFormat) {
        if (item.displayFormat.textAlign) {
          classes.push(`align-${item.displayFormat.textAlign}`);
        }
      }

      if (this.legacyMode /* && (item.type !== 'label' && !item.isLabel) */) {
        classes.push('legacy');
      }

      if (item.addClass) {
        item.addClass.forEach((c: string) => {
          classes.push(c);
        });
      }
    }

    return classes.join(' ').toString();
  }

  // ================================================================
  // FUNCTION KEYS
  // ================================================================
  /**
   * Formats function keys to support grouping.
   */
  formatFunctionKeys(): any {
    const keys: any[] = [];
    if (this.props.screenDef.functionKeys && this.props.screenDef.functionKeys.length) {
      this.props.screenDef.functionKeys.forEach((functionKey: any): void => {
        // Get condition related attributes
        const conditions = this.conditions.functionKeys[functionKey.key];
        const hidden = conditions && conditions.hidden;
        const label = (conditions && conditions.label) ?
          conditions.label : functionKey.label;
        const disabled = conditions && conditions.protect;
        const key = functionKey.key;
        if (!hidden) {
          if (functionKey.group && functionKey.group !==
            '' && window.config['ui']['funKeyGroups']) {
            // Get group of name
            let group = keys.find((obj: any): any => obj.label === functionKey.group);
            // Add group if it doesn't exist
            if (!group) {
              keys.push({
                label: functionKey.group,
                items: [
                  []
                ],
                template: (item: any, options: any): any => {
                  return (<span data-pr-tooltip={key}> {item.label} </span>);
                }
              });
            }
            // Get group of name again
            group = keys.find((obj: any): any => obj.label === functionKey.group);
            // Add item to group
            group.items[0].push({
              label,
              items: [{
                label,
                disabled,
                command: (): any => {
                  this.defaultAction(functionKey.command);
                },
                template: (item: any, options: any): any => {
                  return (
                    /* eslint-disable */
                    <a href="#" className="p-menuitem-link" role="menuitem" aria-haspopup="false" data-pr-tooltip={key} target={item.target} onClick={options.onClick}>
                      <div className="fcnkey-label">{item.label}</div>
                      <div className="fcnkey-key">{key}</div>
                    </a>
                    /* eslint-enable */
                  );
                }
              }]
            });
          } else {
            keys.push({
              label,
              disabled,
              command: (): any => {
                this.defaultAction(functionKey.command);
              },
              template: (item: any, options: any): any => {
                return (
                  <span data-pr-tooltip={key}
                    className="customClassName">{item.label}</span>
                );
              }
            });
          }
        }
      });
    }
    return keys;
  }

  // ================================================================
  //  INPUTS
  // ================================================================

  /**
   * Splits inputs into rows, based on 'y' positionning.
   */
  formatInputs(inputs: any): any[] {
    if (inputs && inputs.length > 0) {
      const rows: any[] = [[]];
      let numRows = 1;
      let currentRow = inputs[0].pos.y;
      // split inputs by rows

      inputs.forEach((input: any): any => {
        const row = input.pos.y;

        if (currentRow !== row) {
          rows.push([]);
          currentRow = row;
          numRows++;
        }
        input.pos.y = numRows;
        rows[rows.length - 1].push(input);
      });

      return rows;
    }
  }

  /**
   * Returns the options with translated labels
   */
  getDropdownValues(options: any): any {
    if (options && options.length) {
      options.forEach((option: any, index: number): void => {
        options[index].label = option.label;
      });
    }
    return options;
  }

  /**
   * Event called when an input is focused
   */
  onFieldFocus(fieldName: string, shortFieldName: string): void {
    this.focusedField = fieldName;
    this.focusedFieldId = shortFieldName;
    // row zero means no row in datatable
    this.tablerowFocusedField = -1;
  }

  getRightLabelClass(input: any): string {
    return 'right-label col ' + (this.legacyMode ? 'col-' + (input.size + input.label.length + 1) : 'col-' + input.size) + (this.legacyMode ? ' legacy' : '');
  }

  // ================================================================
  //  GRID
  // ================================================================

  /**
   * Grid event on field focus
   */
  onTableFieldFocus(fieldName: string, shortFieldName: string, rowNumber: number): void {
    this.focusedField = fieldName;
    this.focusedFieldId = shortFieldName;
    this.tablerowFocusedField = rowNumber % this.props.formData['subfilePageSize'];
  }

  /**
   * code on table action buttons, setprogramMode if delete, and
   * affect recordDataChanged and selected of selected rows..
   * and call reply
   */
  tableAction(command: string): void {
    this.showLoader();

    const replyFormData = this.formData;
    for (let i = 0; i < replyFormData['subfile'].length; i++) {
      if (replyFormData['subfile'][i]['recordSelected'] === 'Y') {
        replyFormData['subfile'][i]['recordDataChanged'] = 'Y';
        replyFormData['subfile'][i][this.selectedActionField] = command;
      }
    }

    replyFormData['gridAction'] = command;
    replyFormData['cmdKey'] = '00';

    this.replyToBackend(replyFormData);
  }

  /**
   * after page change, sent reply
   */
  pageChanged(event: string): void {
    this.showLoader();

    const replyFormData = this.formData;
    if (event === 'previous') {
      replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['PageUp'] : '07';
    } else if (event === 'next') {
      replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['PageDown'] : '08';
    } else if (event === 'reset') {
      replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['Reset'] : '05';
    }

    this.replyToBackend(replyFormData);
  }

  /**
   * Returns if the page should show the grid, based on the
   * 'screenControl' variables passed from the DTO.
   */
  showGrid(): boolean {
    return this.props.formData && (this.props.formData['screenControl'] === undefined || this.props.formData['screenControl'] !== 'H');
  }

  /**
   * returns the max length of a filter based on its definitions
   */
  getFilterMaxLength(column: any, filter: any): number {
    if (filter.displayFormat && filter.displayFormat.textLength !== undefined && filter.displayFormat.textLength > 0) {
      return filter.displayFormat.textLength;
    } else if (column.displayFormat && column.displayFormat.textLength) {
      return column.displayFormat.textLength;
    } else if (filter.textLength !== undefined && filter.textLength > 0) {
      return filter.textLength;
    } else if (column.textLength) {
      return column.textLength;
    }
  }

  /**
   * Grid Message handler - generate msg for each one contained in the DTO (duplicate message appearing on the grid in the toast messager)
   */
  onGridError(event: any): void {
    event.forEach((e: any): any => {
      toast.error((e.messageText + ' - ' + e.fieldsName.join(',')), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    });
  }

  // ================================================================
  //  BACKEND
  // ================================================================

  /**
   * code called from functionkeys
   */
  promptAction(fieldName: string, shortFieldName: string): void {
    this.showLoader();
    const replyFormData = this.formData;
    const command = '04'; // prompt command number = 04

    replyFormData['cursorField'] = fieldName;
    if (replyFormData['cursorFieldId']) {
      replyFormData['cursorFieldId'] = shortFieldName;
    }
    replyFormData['cursorTableRow'] = this.tablerowFocusedField;
    replyFormData['cmdKey'] = command;

    this.replyToBackend(replyFormData);
  }

  /**
   * code called from functionkeys
   */
  defaultAction(command: string): void {
    if (command === '03' && this.screenDef.programName === 'Default') {
      const url =
        window.location !== window.parent.location
          ? document.referrer
          : document.location.href;
      console.log("Closing iframe with url", url);
      window.parent.postMessage("close-iframe", url);
    }
    const replyFormData = this.formData;

    this.showLoader();

    replyFormData['cursorField'] = this.focusedField;
    if (replyFormData['cursorFieldId']) {
      replyFormData['cursorFieldId'] = this.focusedFieldId;
    }
    replyFormData['cursorTableRow'] = this.tablerowFocusedField;

    replyFormData['cmdKey'] = command;
    this.replyToBackend(replyFormData);
  }

  /**
   * When the user click on continue button
   */
  onSubmit(): void {
    this.showLoader();

    const replyFormData = this.formData;

    replyFormData['cursorField'] = this.focusedField;
    if (replyFormData['cursorFieldId']) {
      replyFormData['cursorFieldId'] = this.focusedFieldId;
    }
    replyFormData['cursorTableRow'] = this.tablerowFocusedField;

    replyFormData['cmdKey'] = '00';
    this.replyToBackend(replyFormData);
  }

  /**
   * Because we need to set data for terminal lib
   */
  replyToBackend(newFormData: any): void {
    // ... terminal lib need a refresh of its model
    model.next({
      data: newFormData
    });
    this.terminal.reply();
  }

  // ================================================================
  //  UI
  // ================================================================

  /**
   * Shows the loaders.
   */
  showLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.add('show');
    }
  }

  /**
   * 
   * Get if the attribute is readonly
   */
  isReadOnly(input: any, formData: any): boolean {
    let readOnly = false;
    if (input?.initialDisplay) {
      for (let i in input.initialDisplay) {
        if (input.initialDisplay[i].attribute === "protect") {
          if (input.initialDisplay[i].attributeValue) {
            readOnly = true;
          }
        }
      }
    }
    if (input.displayConditions) {
      for (let i in input.displayConditions) {
        if (input.displayConditions[i].attribute === "protect") {
          if (this.formData.fieldConditions[input.field]) {
            readOnly = true;
          }
        }
      }
    }
    return readOnly;
  }

  getTopButtons() {
    const topFunctions = [];
    for(const func in this.functionKeys) { 
      if(this.functionKeys[func].label.includes("Back") || 
      this.functionKeys[func].label.includes("View") ||
      this.functionKeys[func].label == "Note" ||
      this.functionKeys[func].label.includes("Suppress Update") ||
      this.functionKeys[func].label.includes("Sanctions") ||
      this.functionKeys[func].label.includes("Detail") ||
      this.functionKeys[func].label.includes("Suppress Updates") ||
      this.functionKeys[func].label.includes("Edit")) {
        topFunctions.push(this.functionKeys[func]);
      }
    }
    return topFunctions;
  }

  getBottomButtons() {
    const bottomButtons = [];
    for(const func in this.functionKeys) { 
      if(this.functionKeys[func].label.includes("Back") || 
      this.functionKeys[func].label.includes("View") ||
      this.functionKeys[func].label == "Note" ||
      this.functionKeys[func].label.includes("Suppress Update") ||
      this.functionKeys[func].label.includes("Sanctions") ||
      this.functionKeys[func].label.includes("Detail") ||
      this.functionKeys[func].label.includes("Suppress Updates") ||
      this.functionKeys[func].label.includes("Edit")) {
      } else {
        bottomButtons.push(this.functionKeys[func]);
      }
    }
    return bottomButtons;
  }

  /**
   *
   */
  render(): React.ReactElement {
    this.getTopButtons();
    if(this.screenDef.hasTopButtons == null) {
      this.screenDef.hasTopButtons = true;
    }
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={50000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnHover={true}
        />
        {
          this.screenDef && (
            <div className="content-container clearfix">
              {/* <!-- TOOLBAR --> */}
              {this.screenDef.hasTopButtons && 
                <div className="top-buttons">
                  <div id="toolbar" className={this.getToolbarClasses()}>
                    <div className="function-keys">
                      {this.functionKeys && (
                        <MegaMenu ref={(el: any): any => this.menu = el} model={this.getTopButtons()} />
                      )}
                    </div>
                  </div>
                </div>
              }
              {this.screenDef.hasTopButtons && 
                <div id="toolbar" className={this.getToolbarClasses()}>
                  <div className="right-buttons" style={{display: this.screenDef.hasContinueButton === true ? '' : 'none'}}>
                    <FContinueButton
                      id="continue-button"
                      title="Save"
                      handleContinue={(): void => this.onSubmit()}
                      disabled={!this.props.isActiveScreen}
                    />
                  </div >
                </div>
              }
              {/* PROGRAM NAME */}
              {this.props.type !== 'popup' && (
                <div className="container-fluid app-title-header">
                  <h1>{this.screenDef.title} - <span>{this.screenDef.programName}</span></h1>
                </div>)
              }
              {/* <!-- INPUTS --> */}
              {this.formatedInputs && (
                <div className="container-fluid">
                  <form>
                    {this.formatedInputs.map((row: any, rowindex: any): any => (
                      <div className="row" key={rowindex}>
                        {row.map((input: any, index: any): any => (
                          <React.Fragment key={index}>
                            {input.isLabel && (
                              <FLabel
                                id={input.field}
                                name={input.field}
                                text={input.label}
                                color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                              />
                            )}
                            {!input.isLabel && (
                              <div className={'col ' + (this.legacyMode ? 'col-' + (input.size + input.label.length + 1) : 'col-' + input.size) + (this.legacyMode ? ' legacy' : '')}>
                                <div className={this.getItemClasses(input)}>
                                  {/* <!-- TEXT --> */}
                                  {input.type === "string" && (!this.isReadOnly(input, this.formData) ?
                                    <FTextInput
                                      id={input.field}
                                      name={input.field}
                                      value={this.formData['fields'][input.field]}
                                      label={input.labelLocation === 'above' ? input.header1 : input.label}
                                      maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                      required={input.required}
                                      error={this.inputErrors[input.field]}
                                      displayFormat={input.displayFormat}
                                      onValueChange={(newValue: any): void => {
                                        const formData = this.formData;
                                        formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                        this.forceUpdate();
                                      }}
                                      autofocus={this.formData['cursorField'] === input.field}
                                      promptable={input.lookupFlag}
                                      onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                      suffix={input.options ? input.options.suffix : null}
                                      labelOnTop={input.labelLocation === 'above'}
                                      legacy={this.legacyMode}
                                      onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                      onBlur={(): void => { }}
                                      color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                      readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                      highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                      protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                      reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                      underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                      hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    />
                                    : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                  )
                                  }
                                  {/* <!-- MULTILINE --> */}
                                  {input.type === "multiline" && (!this.isReadOnly(input, this.formData) ?
                                    <FMultilineInput
                                      id={input.field}
                                      name={input.field}
                                      value={this.formData['fields'][input.field]}
                                      label={input.labelLocation === 'above' ? input.header1 : input.label}
                                      maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                      required={input.required}
                                      error={this.inputErrors[input.field]}
                                      displayFormat={input.displayFormat}
                                      onValueChange={(newValue: any): void => {
                                        const formData = this.formData;
                                        formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                        this.forceUpdate();
                                      }}
                                      autofocus={this.formData['cursorField'] === input.field}
                                      promptable={input.lookupFlag}
                                      onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                      suffix={input.options ? input.options.suffix : null}
                                      labelOnTop={input.labelLocation === 'above'}
                                      legacy={this.legacyMode}
                                      onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                      onBlur={(): void => { }}
                                      color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                      readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                      highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                      protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                      reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                      underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                      hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    /> : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                  )}
                                  {/* <!-- NUMERIC --> */}
                                  {input.type === "number" && (!this.isReadOnly(input, this.formData) ?
                                    < FNumericInput
                                      id={input.field}
                                      name={input.field}
                                      value={this.formData['fields'][input.field]}
                                      label={input.labelLocation === 'above' ? input.header1 : input.label}
                                      maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                      required={input.required}
                                      error={this.inputErrors[input.field]}
                                      displayFormat={input.displayFormat}
                                      onValueChange={(newValue: any): void => {
                                        const formData = this.formData;
                                        formData['fields'][input.field] = newValue;
                                        this.forceUpdate();
                                      }}
                                      autofocus={this.formData['cursorField'] === input.field}
                                      promptable={input.lookupFlag}
                                      onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                      suffix={input.options ? input.options.suffix : null}
                                      labelOnTop={input.labelLocation === 'above'}
                                      legacy={this.legacyMode}
                                      onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                      onBlur={(): void => { }}
                                      color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                      readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                      highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                      protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                      reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                      underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                      hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    />
                                    : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                  )
                                  }
                                  {/* <!-- DATE & TIME --> */}
                                  {(input.type === "datetime" || input.type === "date" || input.type === "time") && (!this.isReadOnly(input, this.formData) ?
                                    <FDateTimeInput
                                      id={input.field}
                                      type={input.type}
                                      dateFormat={this.dateFormat}
                                      timeSeparator={this.timeSeparator}
                                      showSeconds={this.showSeconds}
                                      name={input.field}
                                      value={this.formData['fields'][input.field]}
                                      label={input.labelLocation === 'above' ? input.header1 : input.label}
                                      maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                      required={input.required}
                                      error={this.inputErrors[input.field]}
                                      displayFormat={input.displayFormat}
                                      onValueChange={(newValue: any): void => {
                                        const formData = this.formData;
                                        formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                        this.forceUpdate();
                                      }}
                                      autofocus={this.formData['cursorField'] === input.field}
                                      promptable={input.lookupFlag}
                                      onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                      suffix={input.options ? input.options.suffix : null}
                                      labelOnTop={input.labelLocation === 'above'}
                                      legacy={this.legacyMode}
                                      onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                      onBlur={(): void => { }}
                                      color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                      readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                      highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                      protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                      reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                      underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                      hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    />
                                    : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                  )
                                  }
                                  {input.type === "search-dropdown" && (!this.isReadOnly(input, this.formData) ?
                                    <FSelectInput
                                      id={input.field}
                                      customValue={input.customValue}
                                      name={input.field}
                                      values={this.getDropdownValues(input.options)}
                                      value={this.formData['fields'][input.field]}
                                      label={input.labelLocation === 'above' ? input.header1 : input.label}
                                      maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                      required={input.required}
                                      error={this.inputErrors[input.field]}
                                      displayFormat={input.displayFormat}
                                      onValueChange={(newValue: any): void => {
                                        const formData = this.formData;
                                        formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                        formData['cmdKey'] = "00";
                                        this.forceUpdate();
                                        this.showLoader();
                                        this.replyToBackend(formData);
                                      }}
                                      autofocus={this.formData['cursorField'] === input.field}
                                      promptable={input.lookupFlag}
                                      onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                      suffix={input.options ? input.options.suffix : null}
                                      labelOnTop={input.labelLocation === 'above'}
                                      legacy={this.legacyMode}
                                      onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                      onBlur={(): void => { }}
                                      color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                      readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                      highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                      protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                      reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                      underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                      hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    />
                                    : <TextDisplay input={input} formData={this.formData} dropdownValue={this.getDropdownValues(input.options)}></TextDisplay>
                                  )
                                  }
                                  {/* <!-- SELECT --> */}
                                  {input.type === "dropdown" && (!this.isReadOnly(input, this.formData) ?
                                    <FSelectInput
                                      id={input.field}
                                      customValue={input.customValue}
                                      name={input.field}
                                      values={this.getDropdownValues(input.options)}
                                      value={this.formData['fields'][input.field]}
                                      label={input.labelLocation === 'above' ? input.header1 : input.label}
                                      maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                      required={input.required}
                                      error={this.inputErrors[input.field]}
                                      displayFormat={input.displayFormat}
                                      onValueChange={(newValue: any): void => {
                                        const formData = this.formData;
                                        formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                        this.forceUpdate();
                                      }}
                                      autofocus={this.formData['cursorField'] === input.field}
                                      promptable={input.lookupFlag}
                                      onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                      suffix={input.options ? input.options.suffix : null}
                                      labelOnTop={input.labelLocation === 'above'}
                                      legacy={this.legacyMode}
                                      onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                      onBlur={(): void => { }}
                                      color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                      readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                      highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                      protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                      reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                      underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                      hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    />
                                    : <TextDisplay input={input} formData={this.formData} dropdownValue={this.getDropdownValues(input.options)}></TextDisplay>
                                  )
                                  }
                                </div>
                              </div>
                            )}
                            {input.rightLabel && (
                              <div className={this.getRightLabelClass(input)}>
                                <FLabel
                                  id={input.field}
                                  name={input.field}
                                  text={input.rightLabel}
                                  color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                  readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                  highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                  protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                  reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                  underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                  hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                />
                              </div>
                            )}

                          </React.Fragment>
                        ))}
                      </div>
                    ))
                    }
                  </form>
                </div>
              )}
              {/* <!-- GRID --> */}
              {this.screenDef.gridDefinition && (
                <div>
                  <div className="row"> 
                    <div id="toolbar" className={this.getToolbarClasses()}>
                      <div className="function-keys">
                        {this.functionKeys && (
                          <MegaMenu ref={(el: any): any => this.menu = el} model={this.getBottomButtons()} />
                        )}
                      </div>
                      <div className="export">
                        <div className="right-buttons">       
                          <div className="right-buttons" style={{display: this.screenDef.lowerContinueButton === true ? '' : 'none'}}>
                            <FContinueButton
                              id="continue-button"
                              title="Save"
                              handleContinue={(): void => this.onSubmit()}
                              disabled={!this.props.isActiveScreen}
                            />
                          </div >      
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid" ref={(el: any): any => this.keysNavigation = el}>
                    {this.showGrid() &&
                      <OptumGrid
                        ref={(el: any): any => this.dataGrid = el}
                        model={this.formData}
                        screenDef={this.screenDef}
                        // onModelChange={(event: any): void => { }}
                        onModelChange={(newModel: any): any => { this.formData = newModel; this.forceUpdate(); }}
                        onPageChange={(event: any): void => this.pageChanged(event)}
                        conditions={this.conditions}
                        onActionButton={this.tableAction}
                        dateFormat={this.dateFormat}
                        timeSeparator={this.timeSeparator}
                        showSeconds={this.showSeconds}
                        onTableFieldFocus={(fieldName: string, shortFieldName: string, rowIndex: number): void => this.onTableFieldFocus(fieldName, shortFieldName, rowIndex)}
                      />
                    }
                  </div>
                </div>
              )
              }
            </div>
          )
        }
      </React.Fragment >
    );
  }
}

export default BaseComponent;
