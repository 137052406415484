import * as React from 'react';
import BaseComponent from '../base';
import AppFooter from '../../layout/app-footer/AppFooter';

declare global {
  interface Window { config: any; }
}

/**
 *
 */
export interface AppHeaderProps {
  screenDef: any;
  formData: any;
}

/**
 *
 */
class BasePopup<P = {}, S = any> extends React.Component<AppHeaderProps, any> {
  /**
   *
   */
  render(): React.ReactElement {
    return (
      <React.Fragment>
        {this.props.screenDef && this.props.formData && (
          <div className="popup-container">
            <BaseComponent type="popup" screenDef={this.props.screenDef} formData={this.props.formData} isActiveScreen={true} />
          </div>
        )
        }
      </React.Fragment >
    );
  }
}

export default BasePopup;
