import * as React from 'react';
import Base from '../../base/base';
import { MegaMenu } from 'primereact/megamenu';
import { Tooltip } from 'primereact/tooltip';
import { ToastContainer } from 'react-toastify';
import { FContinueButton, FGrid } from '@fresche/ui-lib-react';
import TextDisplay from '../../layout/text-display/text-display';
import { FNumericInput, FSelectInput, FTextInput, FLabel, FDateTimeInput, FMultilineInput } from '@fresche/ui-lib-react';

export default class CustomBase extends Base {
    
    // Overwrite the render() method so we can allow custom user Components to draw
    // their own full output
    render(): React.ReactElement {

        // Populate the properties of our known child element types with their expected values. This is 
        // required as most of these properties are only available in Base, but the user Component is
        // an extension of BaseView, so doesn't have access to these directly.
        const children = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {

                // Currently Grid is the only element that has been implemented with extensive property
                // support. We create a clone of the child, setting all of the properties required
                // by FGrid.
                if (child.type === FGrid) {
                    return React.cloneElement(child, {
                        ref: (el: any): any => this.dataGrid = el,
                        onModelChange: (newModel: any): any => {
                            if (child.props.onModelChange) {
                                child.props.onModelChange();
                            }
                            this.formData = newModel;
                            this.forceUpdate();
                        },
                        onPageChange: (event: any): void => {
                            if (child.props.onPageChange) {
                                child.props.onPageChange();
                            }
                            this.pageChanged(event);
                        },
                        conditions: child.props.conditions ? child.props.conditions : this.conditions,
                        onActionButton: child.props.onActionButton ? child.props.onActionButton : this.tableAction,
                        dateFormat: child.props.dateFormat ? child.props.dateFormat : this.dateFormat,
                        timeSeparator: child.props.timeSeparator ? child.props.timeSeparator : this.timeSeparator,
                        showSeconds: child.props.showSeconds ? child.props.showSeconds : this.showSeconds,
                        onTableFieldFocus: (fieldName: string, shortFieldName: string, rowIndex: number): void => {
                            if (child.props.onTableFieldFocus) {
                                child.props.onTableFieldFocus(fieldName, shortFieldName, rowIndex);
                            }
                            this.onTableFieldFocus(fieldName, shortFieldName, rowIndex)
                        }
                    });
                }
            }
            return child;
        });
        // We output the common shared components (Error container, and function keys), then
        // output our modified child elements.
        return (
            <React.Fragment>
                {/* <!-- Toast container used for showing errors --> */}
                <ToastContainer
                    position="top-right"
                    autoClose={50000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnHover={true}
                />
                <div className="content-container clearfix">
                <div className="container-fluid app-title-header">
                  <h1>{this.screenDef.title} - <span>{this.screenDef.programName}</span></h1>
                </div>
                    {/* <!-- INPUTS --> */}
                    {this.formatedInputs && (
                        <div className="container-fluid">
                        <form>
                            {this.formatedInputs.map((row: any, rowindex: any): any => (
                            <div className="row" key={rowindex}>
                                {row.map((input: any, index: any): any => (
                                <React.Fragment key={index}>
                                    {input.isLabel && (
                                    <FLabel
                                        id={input.field}
                                        name={input.field}
                                        text={input.label}
                                        color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                        readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                        highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                        protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                        reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                        underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                        hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    />
                                    )}
                                    {!input.isLabel && (
                                    <div className={'col ' + (this.legacyMode ? 'col-' + (input.size + input.label.length + 1) : 'col-' + input.size) + (this.legacyMode ? ' legacy' : '')}>
                                        <div className={this.getItemClasses(input)}>
                                        {/* <!-- TEXT --> */}
                                        {input.type === "string" && (!this.isReadOnly(input, this.formData) ?
                                            <FTextInput
                                            id={input.field}
                                            name={input.field}
                                            value={this.formData['fields'][input.field]}
                                            label={input.labelLocation === 'above' ? input.header1 : input.label}
                                            maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                            required={input.required}
                                            error={this.inputErrors[input.field]}
                                            displayFormat={input.displayFormat}
                                            onValueChange={(newValue: any): void => {
                                                const formData = this.formData;
                                                formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                                this.forceUpdate();
                                            }}
                                            autofocus={this.formData['cursorField'] === input.field}
                                            promptable={input.lookupFlag}
                                            onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                            suffix={input.options ? input.options.suffix : null}
                                            labelOnTop={input.labelLocation === 'above'}
                                            legacy={this.legacyMode}
                                            onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                            onBlur={(): void => { }}
                                            color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                            readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                            highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                            protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                            reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                            underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                            hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                            />
                                            : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                        )
                                        }
                                        {/* <!-- MULTILINE --> */}
                                        {input.type === "multiline" && (!this.isReadOnly(input, this.formData) ?
                                            <FMultilineInput
                                            id={input.field}
                                            name={input.field}
                                            value={this.formData['fields'][input.field]}
                                            label={input.labelLocation === 'above' ? input.header1 : input.label}
                                            maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                            required={input.required}
                                            error={this.inputErrors[input.field]}
                                            displayFormat={input.displayFormat}
                                            onValueChange={(newValue: any): void => {
                                                const formData = this.formData;
                                                formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                                this.forceUpdate();
                                            }}
                                            autofocus={this.formData['cursorField'] === input.field}
                                            promptable={input.lookupFlag}
                                            onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                            suffix={input.options ? input.options.suffix : null}
                                            labelOnTop={input.labelLocation === 'above'}
                                            legacy={this.legacyMode}
                                            onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                            onBlur={(): void => { }}
                                            color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                            readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                            highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                            protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                            reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                            underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                            hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                            /> : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                        )}
                                        {/* <!-- NUMERIC --> */}
                                        {input.type === "number" && (!this.isReadOnly(input, this.formData) ?
                                            < FNumericInput
                                            id={input.field}
                                            name={input.field}
                                            value={this.formData['fields'][input.field]}
                                            label={input.labelLocation === 'above' ? input.header1 : input.label}
                                            maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                            required={input.required}
                                            error={this.inputErrors[input.field]}
                                            displayFormat={input.displayFormat}
                                            onValueChange={(newValue: any): void => {
                                                const formData = this.formData;
                                                formData['fields'][input.field] = newValue;
                                                this.forceUpdate();
                                            }}
                                            autofocus={this.formData['cursorField'] === input.field}
                                            promptable={input.lookupFlag}
                                            onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                            suffix={input.options ? input.options.suffix : null}
                                            labelOnTop={input.labelLocation === 'above'}
                                            legacy={this.legacyMode}
                                            onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                            onBlur={(): void => { }}
                                            color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                            readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                            highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                            protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                            reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                            underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                            hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                            />
                                            : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                        )
                                        }
                                        {/* <!-- DATE & TIME --> */}
                                        {(input.type === "datetime" || input.type === "date" || input.type === "time") && (!this.isReadOnly(input, this.formData) ?
                                            <FDateTimeInput
                                            id={input.field}
                                            type={input.type}
                                            dateFormat={this.dateFormat}
                                            timeSeparator={this.timeSeparator}
                                            showSeconds={this.showSeconds}
                                            name={input.field}
                                            value={this.formData['fields'][input.field]}
                                            label={input.labelLocation === 'above' ? input.header1 : input.label}
                                            maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                            required={input.required}
                                            error={this.inputErrors[input.field]}
                                            displayFormat={input.displayFormat}
                                            onValueChange={(newValue: any): void => {
                                                const formData = this.formData;
                                                formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                                this.forceUpdate();
                                            }}
                                            autofocus={this.formData['cursorField'] === input.field}
                                            promptable={input.lookupFlag}
                                            onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                            suffix={input.options ? input.options.suffix : null}
                                            labelOnTop={input.labelLocation === 'above'}
                                            legacy={this.legacyMode}
                                            onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                            onBlur={(): void => { }}
                                            color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                            readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                            highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                            protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                            reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                            underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                            hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                            />
                                            : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                        )
                                        }
                                        {input.type === "search-dropdown" && (!this.isReadOnly(input, this.formData) ?
                                            <FSelectInput
                                            id={input.field}
                                            customValue={input.customValue}
                                            name={input.field}
                                            values={this.getDropdownValues(input.options)}
                                            value={this.formData['fields'][input.field]}
                                            label={input.labelLocation === 'above' ? input.header1 : input.label}
                                            maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                            required={input.required}
                                            error={this.inputErrors[input.field]}
                                            displayFormat={input.displayFormat}
                                            onValueChange={(newValue: any): void => {
                                                const formData = this.formData;
                                                formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                                formData['cmdKey'] = "00";
                                                this.forceUpdate();
                                                this.showLoader();
                                                this.replyToBackend(formData);
                                            }}
                                            autofocus={this.formData['cursorField'] === input.field}
                                            promptable={input.lookupFlag}
                                            onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                            suffix={input.options ? input.options.suffix : null}
                                            labelOnTop={input.labelLocation === 'above'}
                                            legacy={this.legacyMode}
                                            onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                            onBlur={(): void => { }}
                                            color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                            readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                            highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                            protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                            reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                            underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                            hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                            />
                                            : <TextDisplay input={input} formData={this.formData} dropdownValue={this.getDropdownValues(input.options)}></TextDisplay>
                                        )
                                        }
                                        {/* <!-- SELECT --> */}
                                        {input.type === "dropdown" && (!this.isReadOnly(input, this.formData) ?
                                            <FSelectInput
                                            id={input.field}
                                            customValue={input.customValue}
                                            name={input.field}
                                            values={this.getDropdownValues(input.options)}
                                            value={this.formData['fields'][input.field]}
                                            label={input.labelLocation === 'above' ? input.header1 : input.label}
                                            maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                            required={input.required}
                                            error={this.inputErrors[input.field]}
                                            displayFormat={input.displayFormat}
                                            onValueChange={(newValue: any): void => {
                                                const formData = this.formData;
                                                formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                                this.forceUpdate();
                                            }}
                                            autofocus={this.formData['cursorField'] === input.field}
                                            promptable={input.lookupFlag}
                                            onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                            suffix={input.options ? input.options.suffix : null}
                                            labelOnTop={input.labelLocation === 'above'}
                                            legacy={this.legacyMode}
                                            onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                            onBlur={(): void => { }}
                                            color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                            readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                            highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                            protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                            reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                            underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                            hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                            />
                                            : <TextDisplay input={input} formData={this.formData} dropdownValue={this.getDropdownValues(input.options)}></TextDisplay>
                                        )
                                        }
                                        </div>
                                    </div>
                                    )}
                                    {input.rightLabel && (
                                    <div className={this.getRightLabelClass(input)}>
                                        <FLabel
                                        id={input.field}
                                        name={input.field}
                                        text={input.rightLabel}
                                        color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                        readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                        highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                        protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                        reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                        underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                        hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                        />
                                    </div>
                                    )}

                                </React.Fragment>
                                ))}
                            </div>
                            ))
                            }
                        </form>
                        </div>
                    )}
                    <div id="toolbar" className={this.getToolbarClasses()}>
                        <div className="function-keys">
                            {this.functionKeys && (
                                <MegaMenu ref={(el: any): any => 
                                this.menu = el} 
                                model={this.functionKeys} 
                                />
                            )}
                        </div>
                        <div className="right-buttons" style={{display: this.screenDef.hasContinueButton === true ? 'block' : 'none'}}>
                            <FContinueButton
                                id="continue-button"
                                title="Next →"
                                handleContinue={(): void => this.onSubmit()}
                                disabled={!this.props.isActiveScreen}
                            />
                        </div >
                    </div>
                    <br></br>
                    {/* <!-- Modified child elements --> */}
                    {children}
                </div>
            </React.Fragment>
        );
    }
}